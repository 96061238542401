import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/button/button'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import PageHeader from 'components/page-header/page-header'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  useProfileQuery,
  useUpdateUserMutation,
} from 'generated/__generated_graphql'
import useFileUpload, { ActionStatus, UploadObject } from 'hooks/useFileUpload'

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { extractGraphqlErrors } from 'utils/helpers'
import { z } from 'zod'

import { email, firstName, lastName, username } from 'utils/schemas'
import { StyledContent } from '../account.styles'

const formSchema = z.object({
  firstName,
  lastName,
  username,
  email,
})

type FormFields = z.infer<typeof formSchema>

const Profile = () => {
  const [{ data: profileQueryData }, queryProfile] = useProfileQuery()

  const { profile } = profileQueryData ?? {}
  const { firstName, lastName, email, username, upload } = profile ?? {}

  const {
    register,
    formState: { errors, isSubmitting, isDirty },
    getValues,
    reset,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    if (profile && !isDirty) {
      reset({
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        username: username ?? '',
        email: email ?? '',
      })
    }
  }, [profile])

  const notify = useToast()

  const [, updateUser] = useUpdateUserMutation()
  const [uploadedObject, setUploadedObject] = useState<UploadObject>()

  const fileUpload = useFileUpload({
    uploadOnChange: true,
    maxMegabyte: 10,
    onUploadStatusChange,
    getUploadedObject: setUploadedObject,
  })

  const {
    // uploadObject, handleRemove,
    uploadStatus,
  } = fileUpload

  async function callUpdateUserMutation(removePhoto = false) {
    try {
      // a user's profilePic url is unset if you call updateUser without an upload id(upload.id)
      // i.e uploadId == undefined
      const uploadId = removePhoto
        ? undefined
        : uploadedObject?.id
        ? String(uploadedObject?.id)
        : upload?.id
        ? String(upload?.id)
        : undefined

      const { firstName, lastName, email, username } = getValues()

      const response = await updateUser({
        input: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          username: username,
          uploadId,
        },
      })

      const error = extractGraphqlErrors(response, 'updateUser')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      queryProfile({
        requestPolicy: 'network-only',
      })

      notify({
        status: 'success',
        content: 'Your profile has been successfully updated',
      })
    } catch (error) {
      notify({
        status: 'error',
        content:
          'Something went wrong while updating profile. Please try again',
      })
    }
  }

  async function onUploadStatusChange(status: ActionStatus) {
    switch (status) {
      case 'success': {
        await callUpdateUserMutation()
        return
      }
    }
  }

  // async function onRemovePhoto() {
  //   handleRemove()
  //   callUpdateUserMutation(true)
  // }

  async function onSubmit() {
    await callUpdateUserMutation()
  }

  if (!profile)
    return (
      <Flex
        direction={'column'}
        justify="center"
        align={'center'}
        css={{ height: '100vh', width: '100%' }}
      >
        <Loader size={'md'} />
      </Flex>
    )

  return (
    <Flex direction="column" gutter="3">
      <PageHeader
        title="Profile"
        subtitle="Manage your Centrum profile"
        type="plain"
      />

      <StyledContent direction="column" gutter="3">
        <Text css={{ px: '$4' }} size="md" color="$primary" weight="bold">
          User Details
        </Text>
        <Flex css={{ px: '$4' }} direction="column" gutter="2">
          {/* <Avatar
            size="xtraLarge"
            src={profileImageUrl ?? uploadObject?.url ?? undefined}
            backgroundImage="linear-gradient(180deg, #FCBA7D -18.75%, #F7D6D4 100%);"
          />
          <Text size="xs" css={{ mt: '$5', fontFamily: '$bold' }}>
            Profile Picture
          </Text>
          <Text size="xs" color="$secondary">
            A profile picture is optional but strongly recommended.
          </Text>
          <Flex gutter="3" css={{ mt: '$4' }}>
            <UploadFileInput {...fileUpload} />
            <Button
              appearance="secondary"
              onClick={onRemovePhoto}
              disabled={uploadStatus === 'loading'}
            >
              Remove Photo
            </Button>
          </Flex> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              direction="column"
              gutter="4"
              css={{ mt: '$1', width: '100%' }}
            >
              <Flex
                direction="column"
                gutter="4"
                css={{ mt: '$5', width: '70%' }}
              >
                <Input
                  {...(register('firstName', {
                    required: `Please enter your first name`,
                  }) as any)}
                  error={errors.firstName?.message}
                  label="First Name"
                  appearance="outline"
                  placeholder="Enter your first name"
                  required
                />
                <Input
                  {...(register('lastName', {
                    required: `Please enter your first name`,
                  }) as any)}
                  error={errors.lastName?.message}
                  label="Last Name"
                  placeholder="Enter your last name"
                  required
                  appearance="outline"
                />
                <Input
                  {...(register('username', {}) as any)}
                  error={errors.username?.message}
                  label="Username"
                  appearance="outline"
                  placeholder="Enter your username"
                />
                <Input
                  {...(register('email', {
                    setValueAs: (value) => (value as string).toLowerCase(),
                  }) as any)}
                  error={errors.email?.message}
                  label="Email Address"
                  appearance="outline"
                  placeholder="Enter your email"
                  required
                />
              </Flex>

              <Flex
                justify="end"
                css={{ background: '$settingsFooterbg', p: '$3', mt: '$4' }}
              >
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  disabled={
                    uploadStatus === 'loading' || isSubmitting || !isDirty
                  }
                >
                  Update
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </StyledContent>
    </Flex>
  )
}

export default Profile
