import Button from 'components/button/button'
import CheckBox from 'components/checkbox/checkbox'
import { Drawer } from 'components/drawer/drawer'
import InputLabel from 'components/input/components/input-label'
import Input from 'components/input/input'
import { Stack } from 'components/layout'
import Flex from 'components/layout/flex'
import Text from 'components/text/text'
import Textarea from 'components/textarea/textarea'
import { useToast } from 'components/toast'
import Tooltip from 'components/tooltip'
import TypeAheadSelect from 'components/type-ahead-select'
import {
  Product,
  ProductCategory,
  useCreateProductMutation,
  useGeneralLedgerAccountsQuery,
  useProductCategoriesQuery,
  useUpdateProductMutation,
} from 'generated/__generated_graphql'
import useDisclosure from 'hooks/useDisclosure'
import useForm from 'hooks/useForm'
import React, { useEffect, useState } from 'react'
import { HiArchive, HiPlus } from 'react-icons/hi'
import { isUndefinedOrNull } from 'utils/assertions'
import { extractGraphqlErrors } from 'utils/helpers'
import { omit } from 'utils/object'
import { NewProductCategoryDrawer } from './new-product-category-drawer'

interface IProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (val?: any) => void
  product?: Product
}

const defaultFields = {
  name: '',
  description: '',
  productCategoryId: '',
  glAccountId: '',
  purchasePrice: 0,
  quantity: 0,
  salePrice: 0,
  sku: '',
  showOnInvoices: false,
  showOnPurchases: false,
  tracked: false,
}

export const AddNewProductDrawer: React.FC<IProps> = ({
  visible,
  onClose,
  onSuccess,
  product,
}) => {
  const notify = useToast()
  const [uploadId, setUploadId] = useState<string>()
  const [currentCategoryId, setCurrentCategoryId] = useState()
  const {
    register,
    values,
    setInputValue,
    formIsComplete,
    errors,
    bulkUpdate,
  } = useForm({
    fields: defaultFields,
  })

  const isCreate = isUndefinedOrNull(product?.id)

  useEffect(() => {
    if (product) {
      const productInput = omit(product, [
        'productCategory',
        'glAccount',
        'id',
        'createdAt',
        'updatedAt',
        '__typename',
      ])
      bulkUpdate({
        ...(productInput as any),
        productCategoryId: product?.productCategory.id,
        glAccountId: product.glAccount?.id,
      })
    } else {
      bulkUpdate(defaultFields)
    }
    setCurrentCategoryId((product?.productCategory?.id ?? '') as any)
  }, [product?.id])

  const { isOpen: showCategoryModal, toggle: toggleCategoryModal } =
    useDisclosure()

  const [{ data: categories }] = useProductCategoriesQuery()

  const [{ data: accounts }] = useGeneralLedgerAccountsQuery()

  const [{ fetching: creatingProduct }, createProductMutation] =
    useCreateProductMutation()

  const [{ fetching: updatingProduct }, updateProductMutation] =
    useUpdateProductMutation()

  function preparePayload() {
    return {
      ...values,
      uploadId,
      purchasePrice: Number(values.purchasePrice),
      salePrice: Number(values.salePrice),
      quantity: Number(values.quantity),
    }
  }

  async function createProduct() {
    try {
      const payload = preparePayload()
      const response = await createProductMutation({
        input: payload,
      })
      const error = extractGraphqlErrors(response, 'createProduct')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({ content: 'Product created successfully', status: 'success' })
      onDismiss()
      onSuccess?.(response.data?.createProduct?.product)
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  async function updateProduct() {
    try {
      const payload = preparePayload()
      const response = await updateProductMutation({
        input: {
          ...payload,
          id: product?.id ?? '',
        },
      })
      const error = extractGraphqlErrors(response, 'updateProduct')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({ content: 'Product updated successfully', status: 'success' })
      onDismiss()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function onSave() {
    const operation = isCreate ? createProduct : updateProduct
    operation()
  }

  function onCategoryCreateSuccess(id: any) {
    setCurrentCategoryId(id)
    toggleCategoryModal()
  }

  function onDismiss() {
    bulkUpdate(defaultFields)
    setCurrentCategoryId(undefined)
    onClose()
  }

  return (
    <Drawer
      title="Add new Product"
      titleIcon={<HiArchive size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onDismiss}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            size="md"
            onClick={onSave}
            disabled={!formIsComplete || creatingProduct || updatingProduct}
          >
            {isCreate ? 'Save Product' : 'Update Product'}
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <Input
          label="Title"
          required
          placeholder="Product name"
          {...register('name')}
          error={errors.name}
        />
        <Textarea
          {...register('description')}
          label="Description"
          required
          placeholder="Add product description ..."
          error={errors.description}
        />

        {/* <Stack>
          <Flex
            justify="between"
            align="center"
            css={{ cursor: 'pointer' }}
            onClick={toggleCategoryModal}
          >
            <InputLabel required>Category</InputLabel>
            <Flex align="center" direction="row" gutter="1" justify="start">
              <Text size="xs" color="$blue">
                Add new category
              </Text>
              <HiPlus color="#398AFA"></HiPlus>
            </Flex>
          </Flex>

          <TypeAheadSelect
            placeholder="Choose a product category"
            required
            name="category"
            options={categories?.productCategories ?? []}
            onChange={(value: string) =>
              setInputValue('productCategoryId', value)
            }
            labelKey="name"
            valueKey="id"
            value={currentCategoryId}
            error={errors.productCategoryId}
            renderValue={(value: ProductCategory) => (
              <Text size="xs" align={'center'}>
                {value.name}
              </Text>
            )}
          />
        </Stack> */}

        {/* <Flex direction="column" gutter="2">
          <InputLabel>
            Image
            <Span css={{ color: '$secondary !important' }}>(Optional)</Span>
          </InputLabel>
          <FileUpload onChange={(val) => setUploadId(val[0].id!)} />
        </Flex> */}

        <Flex gutter="3">
          <Flex align="center" gutter="2">
            <Input
              label="Purchase Price"
              size="sm"
              required
              placeholder="0.00"
              {...register('purchasePrice')}
              error={errors.purchasePrice}
            />
          </Flex>
          <Flex direction="column">
            <Input
              label="Sale Price"
              size="sm"
              required
              placeholder="0.00"
              {...register('salePrice')}
              error={errors.salePrice}
            />
          </Flex>
        </Flex>

        <Flex direction="column" gutter="2">
          <Input
            label="Quantity"
            size="sm"
            required
            placeholder="0"
            {...register('quantity')}
            error={errors.quantity}
          />
        </Flex>

        {/* <TypeAheadSelect
          required
          label="General Ledger Account"
          placeholder="Select account"
          options={accounts?.generalLedgerAccounts ?? []}
          valueKey="id"
          labelKey="name"
          renderValue={(value: any) => (
            <Flex align="center" gutterX="2" css={{ py: '1.2rem' }}>
              <Text size="xs">{value.name}</Text>
            </Flex>
          )}
          value={values.glAccountId}
          onChange={(value) => setInputValue('glAccountId', value as string)}
        /> */}

        <Stack spacing={0}>
          <Flex justify="start" align="start">
            <InputLabel required>SKU</InputLabel>
            <Tooltip
              css={{ ml: 10 }}
              align="center"
              placement="top"
              description="a custom alphanumeric code assigned to each unique product or service your business offers"
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 0C2.9104 0 0 2.68749 0 6C0 9.31444 2.9104 12 6.5 12C10.0896 12 13 9.31444 13 6C13 2.68749 10.0896 0 6.5 0ZM6.5 10.8387C3.60299 10.8387 1.25806 8.67506 1.25806 6C1.25806 3.32673 3.6031 1.16129 6.5 1.16129C9.39593 1.16129 11.7419 3.32591 11.7419 6C11.7419 8.67411 9.39798 10.8387 6.5 10.8387ZM6.5 2.66129C7.10796 2.66129 7.60081 3.11623 7.60081 3.67742C7.60081 4.23861 7.10796 4.69355 6.5 4.69355C5.89204 4.69355 5.39919 4.23861 5.39919 3.67742C5.39919 3.11623 5.89204 2.66129 6.5 2.66129ZM7.96774 8.80645C7.96774 8.96678 7.82692 9.09677 7.65323 9.09677H5.34677C5.17308 9.09677 5.03226 8.96678 5.03226 8.80645V8.22581C5.03226 8.06548 5.17308 7.93548 5.34677 7.93548H5.66129V6.3871H5.34677C5.17308 6.3871 5.03226 6.2571 5.03226 6.09677V5.51613C5.03226 5.3558 5.17308 5.22581 5.34677 5.22581H7.02419C7.19788 5.22581 7.33871 5.3558 7.33871 5.51613V7.93548H7.65323C7.82692 7.93548 7.96774 8.06548 7.96774 8.22581V8.80645Z"
                  fill="#A1AAB7"
                />
              </svg>
            </Tooltip>
          </Flex>
          <Input
            required
            placeholder="AB1099GHQET"
            {...register('sku')}
            error={errors.sku}
          />
        </Stack>

        {/* <CheckBox
          checked={values.showOnPurchases}
          label="Show on Purchases"
          onChange={(e) => setInputValue('showOnPurchases', e.target.checked)}
        />
        <CheckBox
          checked={values.showOnInvoices}
          label="Show on Invoices"
          onChange={(e) => setInputValue('showOnInvoices', e.target.checked)}
        />
        <CheckBox
          checked={values.tracked}
          label="Tracked"
          onChange={(e) => setInputValue('tracked', e.target.checked)}
        /> */}
      </Flex>

      <NewProductCategoryDrawer
        fullHeight={false}
        visible={showCategoryModal}
        onClose={toggleCategoryModal}
        onSuccess={onCategoryCreateSuccess}
      />
    </Drawer>
  )
}
