import Button from 'components/button/button'
import DateInput from 'components/date-input/date-input'
import { Col, Flex } from 'components/layout'
import Row from 'components/layout/row'
import PageHeader from 'components/page-header/page-header'
import PageLoader from 'components/page-loader/page-loader'
import Text from 'components/text/text'
import { addDays } from 'date-fns'
import {
  TopOperatingExpenses,
  useReportOverviewQuery,
} from 'generated/__generated_graphql'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { StyledBox } from 'pages/overview/overview.styles'
import { useAppProvider } from 'providers/app-provider'
import { FiArrowRight } from 'react-icons/fi'
import { HiCalendar, HiChevronDown, HiPlus } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import BarChart from '../components/bar-chart'
import { CardDisplay, Indicator } from '../components/card-display'
import { TopExpenseTable } from '../components/top-expense-table'
import { ReportsPageProps } from '../reports'

const OverviewPage = ({ setShowDrawer }: ReportsPageProps): JSX.Element => {
  const navigate = useNavigate()
  const { organisation } = useAppProvider()

  const currency = organisation?.currency?.symbol as string

  const [{ fetching, data }] = useReportOverviewQuery()
  const reportOverview = data?.reportOverview

  const reportsLabels = reportOverview?.yearProfitRevenue?.map(
    (item) => item?.monthYear
  ) as string[]
  const reportsDataA = reportOverview?.yearProfitRevenue?.map(
    (item) => item?.revenue
  ) as number[]
  const reportsDataB = reportOverview?.yearProfitRevenue?.map(
    (item) => item?.expenses
  ) as number[]
  const reportsDataC = reportOverview?.yearProfitRevenue?.map(
    (item) => item?.netProfit
  ) as number[]

  if (fetching) return <PageLoader />

  return (
    <>
      <PageHeader
        title="Reports"
        extra={
          <Flex align="center" gutter="4">
            <DateInput
              prepend={
                <Flex align="center" gutterX={1}>
                  <HiCalendar color="#ABB3B9" />
                  <Text size="xs" color="$secondary">
                    Date:
                  </Text>{' '}
                </Flex>
              }
              append={<HiChevronDown size="1.9rem" color="#ABB3B9" />}
              maximumDate={addDays(new Date(), 0)}
              required
              placeholder="Select Report Date"
              isMonthPicker
              onChange={(e) => {
                console.log('raw', e.target.value)
                console.log('as date', new Date(e.target.value))
              }}
            />
            <Button
              prepend={<HiPlus />}
              appearance="secondary"
              onClick={() =>
                setShowDrawer({
                  open: true,
                })
              }
            >
              Create custom report
            </Button>
          </Flex>
        }
      />
      <StyledLayoutContent>
        <Flex direction="column" gutter="3" css={{ mt: '$1' }}>
          <Flex align="center" justify="between">
            <Text size="sm" weight="bold" color="$secondary">
              Profit & Loss
            </Text>

            <Flex align="center" gutter={2}>
              {/* <Button append={<HiOutlineDownload />} appearance="secondary">
                Export
              </Button> */}
              <Button
                onClick={() => navigate('/dashboard/reports/profit-loss')}
                append={<FiArrowRight />}
              >
                See details
              </Button>
            </Flex>
          </Flex>

          <Row gutter="3">
            <Col span={4}>
              <CardDisplay
                title="Total Revenue"
                amount={String(reportOverview?.totalRevenue?.amount)}
                currency={currency}
                subTitle={
                  <Indicator
                    percentage={
                      reportOverview?.totalNetProfit?.percentage as number
                    }
                  />
                }
                color="#3C85C4"
                tooltip="This shows the total payments that have been made to your account within the month."
              />
            </Col>
            <Col span={4}>
              <CardDisplay
                title="Total Expenses"
                amount={String(reportOverview?.totalExpenses?.amount)}
                currency={currency}
                subTitle={
                  <Indicator
                    percentage={
                      reportOverview?.totalExpenses?.percentage as number
                    }
                  />
                }
                color="#DBECFD"
                tooltip="This is the money your business has spent on an ongoing, day-to-day basis to run its business for the month."
              />
            </Col>
            <Col span={4}>
              <CardDisplay
                title="Total Net Profit"
                amount={String(reportOverview?.totalNetProfit?.amount)}
                currency={currency}
                subTitle={
                  <Indicator
                    percentage={
                      reportOverview?.totalNetProfit?.percentage as number
                    }
                  />
                }
                color="#262626"
                tooltip="This is the difference between the gross profit or loss and the total indirect income/expenses of your business within the month."
              />
            </Col>
          </Row>

          <StyledBox css={{ height: '35rem' }}>
            <BarChart
              labels={reportsLabels}
              dataA={reportsDataA}
              dataB={reportsDataB}
              dataC={reportsDataC}
              currency={currency}
            />
          </StyledBox>
        </Flex>

        <Flex direction="column" gutter="3" css={{ mt: '$7' }}>
          <Flex align="center" justify="between">
            <Text size="sm" weight="bold" color="$secondary">
              Total Expenses
            </Text>
          </Flex>
          <Row gutter="3">
            <Col span={4}>
              <CardDisplay
                title="Top operating expenses"
                amount={String(reportOverview?.totalOpex?.amount)}
                currency={currency}
                subTitle={
                  <Indicator
                    percentage={reportOverview?.totalOpex?.percentage as number}
                  />
                }
                tooltip="This shows the total payments that have been made to your account within the month."
              />
            </Col>
            <Col span={12}>
              <StyledBox>
                <TopExpenseTable
                  data={
                    reportOverview?.topOperatingExpenses as TopOperatingExpenses[]
                  }
                  currency={currency}
                />
              </StyledBox>
            </Col>
          </Row>
        </Flex>
      </StyledLayoutContent>
    </>
  )
}

export default OverviewPage
