import { ReactComponent as AccounteerLogo } from 'assets/svg/logo.svg'
import Avatar from 'components/avatar/avatar'
import Button from 'components/button/button'
import { Box, Col, Flex } from 'components/layout'
import Row from 'components/layout/row'
import Loader from 'components/loader/loader'
import PageHeader from 'components/page-header/page-header'
import Tag from 'components/tag/tag'
import { Span } from 'components/text/span'
import Text from 'components/text/text'
import { format } from 'date-fns'
import {
  CustomReportViewer,
  NameAmountBreakdown,
  ReportPreview,
  useProfileQuery,
  useReportPreviewQuery,
} from 'generated/__generated_graphql'
import React from 'react'
import { BiCalendar, BiWallet } from 'react-icons/bi'
import { GrAnalytics, GrDocumentPdf } from 'react-icons/gr'
import { HiOutlineLockClosed } from 'react-icons/hi'
import { RiShareLine } from 'react-icons/ri'
import { TbArrowBackUp, TbDeviceAnalytics } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from 'stitches/stitches.config'
import { getToken } from 'utils/auth'
import { HoverCard } from '../components/hover-card'
import { Page, StyledLink } from '../components/report-preview-page'
import ShareReportDrawer from '../components/share-report-drawer'
import { Indicator, SummaryCard } from '../components/summary-card'

const StyledPage = styled(`div`, {
  minHeight: '120vh',
  backgroundColor: '$white',
  boxShadow: '$subtle',
})

export default function ReportsPreview() {
  const navigate = useNavigate()
  const [active, setActive] = React.useState(1)
  const [showDrawer, setShowDrawer] = React.useState(false)
  const page1Ref = React.useRef<HTMLDivElement>(null)
  const page2Ref = React.useRef<HTMLDivElement>(null)
  const page3Ref = React.useRef<HTMLDivElement>(null)
  const { id } = useParams()

  const [{ fetching, data }] = useReportPreviewQuery({
    variables: {
      shareableLink: id as string,
    },
  })

  const [{ data: profileData }] = useProfileQuery()

  const report = data?.reportPreview
  const currency = report?.organisation?.currency?.symbol
  const reportingPeriod = report?.reportingPeriod
  const link = report?.customReport?.shareableLink
  const emails = report?.customReport?.customReportViewers?.map(
    (viewer) => viewer?.email
  )

  const isAuthenticated = getToken() !== null
  const hasAccessToCollaborate = emails?.includes(
    profileData?.profile?.email as string
  )
  const canMakeChanges = isAuthenticated && hasAccessToCollaborate

  const handlePageChange = (
    pageNumber: number,
    ref: React.RefObject<HTMLDivElement>
  ) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
    setActive(pageNumber)
  }

  function sumTotalAmount(data: NameAmountBreakdown[]) {
    return data?.reduce((acc, item) => {
      return acc + item?.amount
    }, 0)
  }

  function transformData(
    data: ReportPreview['profitAndLoss'] | ReportPreview['balanceSheet']
  ) {
    const transformedData = data?.accountSummary?.map((item) => {
      return item?.accountCategoryBreakdown?.map((breakdown) => {
        return {
          label: breakdown?.accountType,
          total: sumTotalAmount(
            breakdown?.accountTypeBreakdown as NameAmountBreakdown[]
          ),
          data: breakdown?.accountTypeBreakdown?.map((item) => {
            return {
              id: item?.glAccountId,
              customReportChatId: item?.customReportChatId,
              subtitle: item?.name,
              amount: item?.amount,
              conversations: item?.conversations,
              resolved: item?.isResolved,
              yearInReview: item?.yearInReview,
            }
          }),
        }
      })
    })

    return transformedData?.reduce((acc, item) => {
      return [
        ...acc,
        ...item.map((item) => {
          return {
            ...item,
          }
        }),
      ]
    }, [])
  }

  const profitLossData = transformData(
    report?.profitAndLoss as ReportPreview['profitAndLoss']
  )

  const balanceSheetData = transformData(
    report?.balanceSheet as ReportPreview['balanceSheet']
  )

  React.useEffect(() => {
    if (fetching) return
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const pageNumber = Number(
              (entry.target as HTMLElement).dataset.page
            )
            setActive(pageNumber)
          }
        })
      },
      {
        root: null,
        rootMargin: '10px',
        threshold: 0.5,
      }
    )

    if (page1Ref.current) {
      observer.observe(page1Ref.current)
    }
    if (page2Ref.current) {
      observer.observe(page2Ref.current)
    }
    if (page3Ref.current) {
      observer.observe(page3Ref.current)
    }

    return () => {
      if (page1Ref.current) {
        observer.unobserve(page1Ref.current)
      }
      if (page2Ref.current) {
        observer.unobserve(page2Ref.current)
      }
      if (page3Ref.current) {
        observer.unobserve(page3Ref.current)
      }
    }
  }, [page1Ref.current, page2Ref.current, page3Ref.current, fetching])

  if (fetching)
    return (
      <Flex
        align="center"
        justify="center"
        css={{
          height: '100vh',
        }}
      >
        <Loader />
      </Flex>
    )

  return (
    <Box
      css={{
        background: '$background',
        minHeight: '100vh',
      }}
    >
      <ShareReportDrawer
        id={id as string}
        reportViewers={
          report?.customReport?.customReportViewers as CustomReportViewer[]
        }
        link={link as string}
        visible={showDrawer}
        onClose={() => setShowDrawer(false)}
      />
      <PageHeader
        title={<AccounteerLogo />}
        extra={
          <Flex align="center" gutter="4">
            {canMakeChanges && (
              <>
                <Button prepend={<GrDocumentPdf />} appearance="secondary">
                  Download
                </Button>
                <Button
                  onClick={() => setShowDrawer(true)}
                  prepend={<RiShareLine />}
                >
                  Share Report
                </Button>
              </>
            )}
            {!isAuthenticated && (
              <Button onClick={() => navigate('/login')}>
                Login to collaborate
              </Button>
            )}
            {!canMakeChanges && isAuthenticated && (
              <Tag prependIcon={<HiOutlineLockClosed />} type="blue">
                You don&apos;t have access to collaborate on this report
              </Tag>
            )}
          </Flex>
        }
      />
      <Row css={{ mt: '$5' }}>
        <Col span={3} css={{ position: 'relative' }}>
          <Flex
            direction="column"
            gutter={2}
            align="end"
            css={{ mt: '$6', position: 'sticky', right: '0', top: '10rem' }}
          >
            <StyledLink
              onClick={() => handlePageChange(1, page1Ref)}
              active={active === 1}
            >
              Executive Summary
            </StyledLink>
            <StyledLink
              onClick={() => handlePageChange(2, page2Ref)}
              active={active === 2}
            >
              Profit & Loss
            </StyledLink>
            <StyledLink
              onClick={() => handlePageChange(3, page3Ref)}
              active={active === 3}
            >
              Balance Sheet
            </StyledLink>
          </Flex>
        </Col>
        <Col
          span={6}
          gutter={6}
          css={{
            mb: '$9',
          }}
        >
          <StyledPage data-page="1" ref={page1Ref}>
            <Flex direction="column" css={{ p: '$4', height: '100%' }}>
              <Flex
                justify="between"
                align="center"
                css={{
                  pb: '$3',
                  borderBottom: '1px solid $border',
                }}
              >
                <Flex gutter={1} align="center">
                  <Avatar
                    size="medium"
                    shape="square"
                    background="black"
                    title={report?.organisation?.name as string}
                  />
                  <Text color="$black" weight="bold">
                    {report?.organisation?.name}.
                  </Text>
                </Flex>
                <Box
                  css={{
                    p: '$2 $1',
                    color: '$white',
                    backgroundColor: '$blue',
                  }}
                >
                  <Text weight="bold" size="xxs">
                    01
                  </Text>
                </Box>
              </Flex>
              <Flex
                align="center"
                gutter={2}
                css={{
                  mt: '4rem',
                  width: 'fit-content',
                  textTransform: 'uppercase',
                  letterSpacing: '0.5rem',
                  mb: '$3',
                }}
              >
                <BiCalendar color="gray" />
                <Text size="xxs" weight="bold" color="gray">
                  {report?.customReport?.reportDate &&
                    format(
                      new Date(report?.customReport?.reportDate),
                      'MMMM yyyy'
                    )}
                </Text>
              </Flex>

              <Text
                color="$black"
                weight="regular"
                css={{
                  fontSize: '6rem',
                  lineHeight: '6rem',
                  letterSpacing: '-0.25rem',
                  // fontFamily: '$space',
                  width: '90%',
                }}
              >
                {report?.customReport?.title}
                {/* <Text
                  color="$secondary"
                  css={{
                    fontFamily: '$space',
                  }}
                > <br />
                  {' '}
                  for march{' '}
                </Text> */}
              </Text>
              <Flex justify="between" align="center" css={{ mt: '$9' }}>
                <Text
                  weight="semi"
                  size="xl"
                  color="$black"
                  css={{
                    lineHeight: '3rem',
                    borderLeft: '2px solid $blue',
                    pl: '$3',
                  }}
                >
                  Executive <br />
                  <Span
                    css={{
                      color: '$secondary',
                    }}
                  >
                    Summary
                  </Span>
                </Text>
                <Text
                  size="xs"
                  color="$secondary"
                  css={{
                    width: '50%',
                  }}
                >
                  {/* <Text weight="bold" color="$black">
                    Hello Adeola, &nbsp;
                  </Text> */}
                  {report?.executiveSummary?.description
                    ?.split('\n')
                    ?.map((item, i) => (
                      <span key={i}>
                        {item}
                        <br />
                        <br />
                      </span>
                    ))}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="between"
                gutter={4}
                css={{ mt: '$9' }}
              >
                <SummaryCard
                  title="Total Revenue"
                  icon={<BiWallet color="black" />}
                  amount={String(
                    report?.executiveSummary?.totalRevenue?.amount
                  )}
                  currency={currency}
                  subTitle={
                    <Indicator
                      percentage={
                        report?.executiveSummary?.totalRevenue
                          ?.percentage as number
                      }
                    />
                  }
                  background="blue"
                />
                <SummaryCard
                  title="Total Expenses"
                  icon={<TbDeviceAnalytics color="black" />}
                  amount={String(
                    report?.executiveSummary?.totalExpenses?.amount
                  )}
                  currency={currency}
                  subTitle={
                    <Indicator
                      percentage={
                        report?.executiveSummary?.totalExpenses
                          ?.percentage as number
                      }
                    />
                  }
                  background="purple"
                />
                <SummaryCard
                  title="Total Net Profit"
                  icon={<GrAnalytics color="black" />}
                  amount={String(
                    report?.executiveSummary?.totalNetProfit?.amount
                  )}
                  currency={currency}
                  subTitle={
                    <Indicator
                      percentage={
                        report?.executiveSummary?.totalNetProfit
                          ?.percentage as number
                      }
                    />
                  }
                  background="gold"
                />
              </Flex>
              <Flex direction="column" gutterY={3} css={{ mt: '$8' }}>
                {report?.executiveSummary?.accountInsight?.map((text) => (
                  <KeyPoint key={text} text={text} />
                ))}
              </Flex>

              <Flex
                justify="between"
                align="center"
                css={{
                  mt: 'auto',
                  pt: '$2',
                  borderTop: '1px solid $border',
                }}
              >
                <Flex gutter={1} align="center">
                  <Text size="xxs" color="$secondary">
                    Prepared by:{' '}
                  </Text>
                  <Avatar
                    title={report?.customReport?.createdBy}
                    size="medium"
                    shape="square"
                  />
                  <Text size="xxs" weight="bold">
                    {report?.customReport?.createdBy}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </StyledPage>
          <Page
            name={report?.organisation?.name as string}
            data-page="2"
            ref={page2Ref}
            pageNumber="02"
            title="Profit & Loss"
            subtitle="This is a financial statement that summarizes the revenues, costs, and expenses incurred during a specified period."
          >
            {profitLossData?.map((item) => {
              return (
                <Flex
                  key={item.label}
                  direction="column"
                  gutter="3"
                  css={{
                    m: '0 auto',
                    mt: '$8',
                    width: '60%',
                  }}
                >
                  <Text
                    size="sm"
                    weight="bold"
                    color="$black"
                    css={{ pl: '$2', textTransform: 'uppercase' }}
                  >
                    {item.label}
                  </Text>
                  {item?.data?.map((data) => (
                    <HoverCard
                      key={data.id}
                      id={data.id}
                      subtitle={data.subtitle}
                      customReportChatId={data.customReportChatId as string}
                      amount={String(data.amount)}
                      currency={currency}
                      resolved={data.resolved as boolean}
                      yearInReview={data.yearInReview}
                      reportingPeriod={reportingPeriod}
                      canMakeChanges={canMakeChanges}
                    />
                  ))}
                  <HoverCard
                    title={`Total ${item.label}`}
                    amount={String(item?.total)}
                    currency={currency}
                  />
                </Flex>
              )
            })}
          </Page>
          <Page
            name={report?.organisation?.name as string}
            data-page="3"
            ref={page3Ref}
            pageNumber="03"
            title="Balance Sheet"
            subtitle="This is a financial statement that reports a company's assets, liabilities and shareholders' equity at a specific point in time."
          >
            {balanceSheetData?.map((item) => {
              return (
                <Flex
                  key={item.label}
                  direction="column"
                  gutter="3"
                  css={{
                    m: '0 auto',
                    mt: '$8',
                    width: '60%',
                  }}
                >
                  <Text
                    size="sm"
                    weight="bold"
                    color="$black"
                    css={{ pl: '$2', textTransform: 'uppercase' }}
                  >
                    {item.label}
                  </Text>
                  {item?.data?.map((data) => (
                    <HoverCard
                      key={data.id}
                      id={data.id}
                      customReportChatId={data.customReportChatId as string}
                      subtitle={data.subtitle}
                      amount={String(data.amount)}
                      currency={currency}
                      resolved={data.resolved as boolean}
                      yearInReview={data.yearInReview}
                      reportingPeriod={reportingPeriod}
                      canMakeChanges={canMakeChanges}
                    />
                  ))}
                  <HoverCard
                    title={`Total ${item.label}`}
                    amount={String(item?.total)}
                    currency={currency}
                  />
                </Flex>
              )
            })}
          </Page>
        </Col>
        <Col span="3">
          <Flex
            align="center"
            justify="center"
            onClick={() => navigate('/dashboard/reports/custom-reports')}
            css={{
              cursor: 'pointer',
              position: 'sticky',
              top: '8rem',
              left: '2rem',
              width: '4rem',
              height: '4rem',
              backgroundColor: '$white',
              boxShadow: '$tiny',
              p: '$2',
              ml: '$3',
              borderRadius: '$3',
            }}
          >
            <TbArrowBackUp size="5rem" color="black" />
          </Flex>
        </Col>
      </Row>
    </Box>
  )
}

function KeyPoint({ text }: { text: string }) {
  return (
    <Text size="xs" color="$secondary">
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.86193 0.195262C5.12228 -0.0650874 5.54439 -0.0650874 5.80474 0.195262L10.4714 4.86193C10.7318 5.12228 10.7318 5.54439 10.4714 5.80474L5.80474 10.4714C5.54439 10.7318 5.12228 10.7318 4.86193 10.4714C4.60158 10.2111 4.60158 9.78895 4.86193 9.5286L8.39052 6L0.666667 6C0.298477 6 0 5.70152 0 5.33333C0 4.96514 0.298477 4.66667 0.666667 4.66667L8.39052 4.66667L4.86193 1.13807C4.60158 0.877722 4.60158 0.455612 4.86193 0.195262Z"
          fill="#1E6DDA"
        />
      </svg>{' '}
      &nbsp; {text}
      {/* <Text color="$primary" weight="bold">
        {' '}
        Sales and Investment
      </Text>{' '}
      entries */}
    </Text>
  )
}
