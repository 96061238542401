import * as Accordion from '@radix-ui/react-accordion'
import { ReactComponent as BlueClipIcon } from 'assets/svg/blue-clip-icon.svg'
import Avatar from 'components/avatar/avatar'
import Button from 'components/button/button'
import { Box } from 'components/layout'
import Flex from 'components/layout/flex'
import PageHeader from 'components/page-header/page-header'
import { Text } from 'components/text/text'
import {
  SmartReportStatus,
  SmartReportsQuery,
  useSmartReportsQuery,
} from 'generated/__generated_graphql'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { BookMeetingButton } from 'pages/meetings/meetings'
import React from 'react'
import { BiXCircle } from 'react-icons/bi'
import { BsFileEarmarkCheckFill } from 'react-icons/bs'
import { FaChevronUp } from 'react-icons/fa'
import { HiDocumentDownload } from 'react-icons/hi'
import {
  MdCloudDone,
  MdOutlineIncompleteCircle,
  MdReviews,
} from 'react-icons/md'
import { RiUploadCloud2Line, RiUploadCloudFill } from 'react-icons/ri'
import { styled } from 'stitches/stitches.config'
import ProcessedReportsTable from './components/processed-service-reports-table'
import UnProcessedReportsTable from './components/unprocessed-service-reports-table'
import { UploadReportDocumentsDrawer } from './components/upload-service-report-drawer'

export const statusColors = {
  [SmartReportStatus.All]: {
    color: '#fff',
    bgColor: '#fff',
  },
  [SmartReportStatus.Completed]: {
    color: '#1CA493',
    bgColor: '#E9F6F2',
  },
  [SmartReportStatus.Processing]: {
    color: '#1B65CC',
    bgColor: '#F8F9FF',
  },
  [SmartReportStatus.Uploaded]: {
    color: '#292929',
    bgColor: '##F8F8F8',
  },
}

export const statusIcons = {
  [SmartReportStatus.All]: {
    icon: <BiXCircle size="1.75rem" />,
  },
  [SmartReportStatus.Uploaded]: {
    icon: <MdCloudDone size="1.75rem" />,
  },
  [SmartReportStatus.Processing]: {
    icon: <MdOutlineIncompleteCircle size="1.75rem" />,
  },
  [SmartReportStatus.Completed]: {
    icon: <BsFileEarmarkCheckFill size="1.5rem" />,
  },
}

export type CleanServiceReport = Exclude<
  SmartReportsQuery['smartReports'],
  undefined | null
>['data'][number]

interface ReportPageProps {}

const ReportPage: React.FC<ReportPageProps> = () => {
  const [showDrawer, setShowDrawer] = React.useState(false)

  const [{ data: allReportsQuery }] = useSmartReportsQuery({
    variables: {
      status: SmartReportStatus.All,
    },
  })

  const { data: allReports } = allReportsQuery?.smartReports ?? {}

  const unprocessedReports =
    allReports?.filter(
      (report) => report.status !== SmartReportStatus.Completed
    ) ?? []

  const processingReports =
    allReports?.filter(
      (report) => report.status === SmartReportStatus.Processing
    ) ?? []

  const processedReports =
    allReports?.filter(
      (report) => report.status === SmartReportStatus.Completed
    ) ?? []

  const title =
    processingReports.length < 1 && unprocessedReports.length < 1
      ? 'Upload document(s)'
      : processingReports.length < 1
      ? 'Uploaded ' + unprocessedReports.length + ' document(s)'
      : 'Processing ' +
        processingReports.length +
        ' out of ' +
        unprocessedReports.length +
        ' uploaded document(s)'

  return (
    <>
      <PageHeader
        css={{ position: 'sticky', zIndex: 0 }}
        title={'Dashboard'}
        extra={
          <Flex gutter="4" align={'center'}>
            <BookMeetingButton />
            <Button
              prepend={<RiUploadCloud2Line size="1.9rem" />}
              onClick={() => setShowDrawer(true)}
            >
              Upload documents
            </Button>
          </Flex>
        }
      />

      <StyledLayoutContent>
        {/* <PageBanner
          title="Welcome to your service dashboard"
          description="Get financial reports, key insights and expert advisory that make understanding your finances clear and easy. Know where you stand now, and in the future."
          actionText="Learn more"
          bgSvg={background}
          appearance="yellow"
          onClick={() =>
            window.location.assign(
              `https://app.guidde.com/share/playbooks/1JFQus7ygwhDWbLx9r5kNH?origin=OW7nanWQuLZ8vjluHSQHqQqPnUz1`
            )
          }
        /> */}

        <Flex direction={'column'} css={{ mt: 33, gap: 6 }}>
          <Text
            size="md"
            css={{
              fontFamily: '$space',
              mb: '$4',
              fontWeight: 500,
              color: '$black',
            }}
          >
            Ready to start using Centrum in just 3 steps?
          </Text>
          <Flex align="center" justify="between" gutter="3">
            <Step
              avatar={<RiUploadCloudFill color="#262626" />}
              title="1. Upload your documents"
              description="Attach as many documents, add a name and some instructions and upload."
            />
            <Step
              avatar={<MdReviews color="#262626" />}
              title="2. Process your report"
              description="We will process your documents and also contact you if more info is needed."
            />
            <Step
              avatar={<HiDocumentDownload color="#262626" />}
              title="3. Download your report"
              description="You will be notified via mail when your report is ready."
            />
          </Flex>
        </Flex>

        <StyledAccordionRoot type="single" collapsible defaultValue="item-1">
          <StyledAccordionItem value="item-1">
            <Flex
              justify={'between'}
              css={{
                height: '4rem',
                width: '100%',
                padding: 21,
                paddingBottom: 0,
              }}
            >
              <Flex css={{ gap: '$2' }} align={'center'}>
                <Text size={'xs'} color="$grayDark2">
                  {title}
                </Text>
              </Flex>

              <StyledAccordionTrigger>
                <Button
                  appearance={'secondary'}
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 5,
                    height: '3.2rem',
                    width: 'fit-content',
                  }}
                >
                  <Text>Collapse</Text>
                  <StyledChevron size={12} />
                </Button>
              </StyledAccordionTrigger>
            </Flex>

            <StyledAccordionContent>
              <Flex
                direction="column"
                css={{ width: 'inherit', gap: '$4', borderRadius: '$4' }}
              >
                {unprocessedReports && unprocessedReports.length > 0 ? (
                  <UnProcessedReportsTable reports={unprocessedReports} />
                ) : (
                  <Button
                    appearance={'ghost'}
                    css={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: 140,
                      boxShadow: '$subtle',
                      padding: '4.4rem 8.6rem',
                      gap: '2.4rem',
                      border: '1px dashed #EEF2F4',
                      borderRadius: '$3',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowDrawer(true)}
                  >
                    <Flex
                      justify={'center'}
                      align={'center'}
                      css={{
                        width: '5.2rem',
                        height: '5.2rem',
                        boxShadow: '$subtle',
                        border: '1px solid #EEF2F4',
                        borderRadius: '$3',
                      }}
                    >
                      <BlueClipIcon />
                    </Flex>
                    <Flex direction={'column'} align={'start'}>
                      <Text size={'xs'}>Click here to submit reports</Text>
                      <Text size={'xxs'} color={'$secondary'}>
                        Supported: jpg, jpeg, pdf, png, tiff, tif, doc, docx,
                        csv, xls, xlsx, xlsm (smaller than 10MB)
                      </Text>
                    </Flex>
                  </Button>
                )}
              </Flex>
            </StyledAccordionContent>
          </StyledAccordionItem>
        </StyledAccordionRoot>

        <Box css={{ my: '3rem' }}>
          <Text
            size="md"
            css={{
              fontFamily: '$space',
              fontWeight: 500,
              color: '$black',
            }}
          >
            Processed Reports
          </Text>
        </Box>

        <Box
          css={{
            width: '100%',
            borderRadius: '$4',
            overflow: 'hidden',
            boxShadow: '$tiny',
          }}
        >
          <ProcessedReportsTable reports={processedReports} />
        </Box>
      </StyledLayoutContent>

      <UploadReportDocumentsDrawer
        visible={showDrawer}
        onClose={() => setShowDrawer(false)}
        refetchReports={() => null}
      />
    </>
  )
}

const StyledAccordionRoot = styled(Accordion.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '$white',
  boxShadow: '$subtle',
  gap: '2rem',
  marginTop: 31,

  // borderBottom: '3px solid #1B65CC',
  borderRadius: '$4',
})

const StyledAccordionItem = styled(Accordion.Item, {
  borderRadius: '$4',
})

const StyledChevron = styled(FaChevronUp, {})

const StyledAccordionTrigger = styled(Accordion.Trigger, {
  all: 'unset',
  borderRadius: '$4',

  '[data-state=closed] &': {
    '& svg': {
      transform: 'rotate(180deg)',
      transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
    },
  },

  '[data-state=open] &': {
    '& svg': {
      transform: 'rotate(0deg)',
      transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
    },
  },
})

const StyledAccordionContent = styled(Accordion.Content, {
  display: 'inline-block',
  width: 'stretch',
  mt: 20,
  borderRadius: '$4',
})

export default ReportPage

function Step({
  avatar,
  title,
  description,
}: {
  avatar: React.ReactNode
  title: string
  description: string
}) {
  return (
    <Flex
      align="center"
      gutter={4}
      css={{ backgroundColor: '#ededed', p: '$4', borderRadius: '$4' }}
    >
      <Avatar background="white" size="large" icon={avatar} />
      <Flex direction="column" gutter="1">
        <Text size="xs" color="black" weight="bold">
          {title}
        </Text>
        <Text size="xxs">{description}</Text>
      </Flex>
    </Flex>
  )
}
