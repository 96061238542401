import Avatar from 'components/avatar'
import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { format } from 'date-fns'
import {
  Invoice,
  InvoiceEnum,
  useConvertOfferToInvoiceMutation,
  useInvoicesQuery,
  useMarkInvoiceAsVoidMutation,
} from 'generated/__generated_graphql'
import useDisclosure from 'hooks/useDisclosure'
import { useAppProvider } from 'providers/app-provider'
import { useState } from 'react'
import {
  BiAlarmExclamation,
  BiBookOpen,
  BiCheckCircle,
  BiEditAlt,
  BiMailSend,
  BiXCircle,
} from 'react-icons/bi'
import { HiDotsHorizontal, HiOutlinePlus } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import {
  downloadFileFromUrl,
  extractGraphqlErrors,
  formatMoney,
  getApiUrl,
} from 'utils/helpers'
import { EditInvoiceDrawer } from './edit-invoice-drawer'
import InvoiceDetail from './invoice-detail'
import { NewInvoiceDrawer } from './new-invoice-drawer'
import RecordPayment from './payment'
import SendInvoice from './send-invoice'
import React from 'react'

interface InvoiceTableProps {
  status?: 'all' | 'draft'
  type: 'invoice' | 'offer'
}

export const InvoiceTable: React.FC<InvoiceTableProps> = ({ status, type }) => {
  const shouldOpenDrawer = useLocation().search.includes('openDrawer=true')
  const [downloading, setDownloading] = useState(false)

  const isInvoice = type === 'invoice'
  const notify = useToast()

  const { organisation } = useAppProvider()

  // const { isOpen: showInvoiceDrawer, toggle: toggleInvoiceDrawer } =
  //   useDisclosure(shouldOpenDrawer)
  const [showInvoiceDrawer, toggleInvoiceDrawer] =
    React.useState(shouldOpenDrawer)

  const { isOpen: showPaymentDrawer, toggle: togglePaymentDrawer } =
    useDisclosure()

  const { isOpen: showPreviewDrawer, toggle: toggleShowPreviewDrawer } =
    useDisclosure()

  const { isOpen: showSendDrawer, toggle: toggleShowSendDrawer } =
    useDisclosure()

  const { isOpen: showEditDrawer, toggle: toggleEditDrawer } = useDisclosure()

  const [currentInvoice, setCurrentInvoice] = useState<Invoice>()
  const [page, setPage] = useState(1)

  // const [{ fetching: exporting }, downloadInvoices] = useInvoiceExportMutation()
  const [, voidInvoiceMutation] = useMarkInvoiceAsVoidMutation()
  const [, convertToInvoiceMutation] = useConvertOfferToInvoiceMutation()
  const [{ fetching: loadingInvoices, data: invoices }, refreshInvoices] =
    useInvoicesQuery({
      // requestPolicy: 'network-only',
      variables: {
        invoiceType: isInvoice ? InvoiceEnum.Invoice : InvoiceEnum.Offer,
        status: status === 'all' ? null : 'draft',
        pagination: {
          page,
          per: 10,
        },
      },
    })
  const pagination = invoices?.invoices?.pagination

  const invoiceDownloadUrl = getApiUrl()

  // async function exportInvoices() {
  //   try {
  //     const response = await downloadInvoices({
  //       input: { export: true },
  //     })
  //     const error = extractGraphqlErrors(response, 'invoiceExport')

  //     if (error) {
  //       notify({ content: error, status: 'error' })
  //       return
  //     }
  //     const url = response?.data?.invoiceExport?.url
  //     const a = document.createElement('a')
  //     a.href = url as string
  //     a.click()
  //     URL.revokeObjectURL(a.href)

  //     notify({
  //       content:
  //         type === 'invoice'
  //           ? 'Invoices exported successfully'
  //           : 'Offers exported successfully',
  //       status: 'success',
  //     })
  //   } catch {
  //     notify({ content: 'Something went wrong', status: 'error' })
  //   }
  // }

  async function downloadInvoicePdf(data?: Invoice) {
    try {
      setDownloading(true)
      await downloadFileFromUrl(
        `${invoiceDownloadUrl}/invoice/export/${data?.id}.pdf`,
        'invoice-pdf',
        'pdf',
        true
      )
      notify({ content: 'Invoices downloaded successfully', status: 'success' })
      setDownloading(false)
    } catch {
      notify({ content: 'Something went wrong', status: 'error' })
      setDownloading(false)
    }
  }

  async function convertToInvoice(offer?: Invoice) {
    try {
      const response = await convertToInvoiceMutation({
        input: { offerId: offer?.id ?? '' },
      })
      const error = extractGraphqlErrors(response, 'convertToInvoice')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Offer converted to invoice successfully',
        status: 'success',
      })
      refreshInvoices({ requestPolicy: 'network-only' })
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function onRecordPayment(data?: Invoice) {
    setCurrentInvoice(data)
    togglePaymentDrawer()
  }

  function onPreviewInvoice(data?: Invoice) {
    setCurrentInvoice(data)
    toggleShowPreviewDrawer()
  }

  function onEditInvoice(data?: Invoice) {
    setCurrentInvoice(data)
    toggleEditDrawer()
  }

  function onSendInvoice(data?: Invoice) {
    if (showPreviewDrawer) {
      toggleShowPreviewDrawer()
    }
    setCurrentInvoice(data)
    toggleShowSendDrawer()
  }

  async function onVoidInvoice(data?: Invoice) {
    try {
      const response = await voidInvoiceMutation({
        input: { invoiceId: data?.id ?? '' },
      })
      const error = extractGraphqlErrors(response, 'markInvoiceAsVoid')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content:
          type === 'invoice'
            ? 'Invoice voided successfully'
            : 'Offer voided successfully',
        status: 'success',
      })
      refreshInvoices({ requestPolicy: 'network-only' })
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function getInvoiceActions(status: string, invoice: Invoice) {
    return [
      {
        label: 'Preview',
        onClick: () => onPreviewInvoice(invoice),
      },
      {
        label: 'Edit',
        onClick: () => onEditInvoice(invoice),
      },
      {
        label: 'Record Payment',
        onClick: () => onRecordPayment(invoice),
        disabled: ['draft', 'paid'].includes(invoice.status),
      },
      {
        label: 'Send',
        onClick: () => onSendInvoice(invoice),
      },
      {
        label: 'Void',
        onClick: () => onVoidInvoice(invoice),
        disabled: ['partially_paid', 'paid', 'void'].includes(invoice.status),
      },
      // {
      //   label: 'Download',
      //   onClick: () => downloadInvoicePdf(invoice),
      // },
    ]
  }

  function getOfferActions(status: string, invoice: Invoice) {
    return [
      {
        label: 'Preview',
        onClick: () => onPreviewInvoice(invoice),
      },
      {
        label: 'Edit',
        onClick: () => onEditInvoice(invoice),
      },
      {
        label: 'Void',
        onClick: () => onVoidInvoice(invoice),
      },
      {
        label: 'Convert to invoice',
        onClick: () => convertToInvoice(invoice),
      },
      // {
      //   label: 'Download',
      //   onClick: () => downloadInvoicePdf(invoice),
      // },
    ]
  }

  function getActions(status: string, invoice: Invoice) {
    return type === 'invoice'
      ? getInvoiceActions(status, invoice)
      : getOfferActions(status, invoice)
  }

  const currency = organisation?.currency.symbol

  const columns: ITableColumn<Invoice>[] = [
    {
      key: 'amount',
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount) => (
        <Text size="xs" color="$primary">
          {formatMoney(amount, currency)}
        </Text>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        <Tag
          type={
            ['partially_paid', 'paid'].includes(status) ? 'blue' : 'default'
          }
        >
          <Flex align="center" gutterX="1" justify="between">
            {['partially_paid', 'paid'].includes(status) && (
              <BiCheckCircle size="1.75rem" />
            )}
            {status === 'void' && <BiXCircle size="1.75rem" />}
            {status === 'draft' && <BiEditAlt size="1.75rem" />}
            {status === 'open' && <BiBookOpen size="1.75rem" />}
            {status === 'sent' && <BiMailSend size="1.75rem" />}
            {status === 'overdue' && <BiAlarmExclamation size="1.75rem" />}

            {status === 'partially_paid' ? 'Partially paid' : status}
          </Flex>
        </Tag>
      ),
    },
    {
      key: 'id',
      title: isInvoice ? 'Invoice Number' : 'Offer Number',
      dataIndex: 'reference',
      render: (ref) => (
        <Text size="xs" color="$primary">
          {ref}
        </Text>
      ),
    },
    {
      key: 'customer',
      title: 'Customer',
      dataIndex: 'customer',
      render: (customer) => (
        <Flex align="center" gutterX="2">
          <Avatar title={customer.name} size="medium" />
          <Text size="xs">{customer.name}</Text>
        </Flex>
      ),
    },
    {
      key: 'dueDate',
      title: 'Due Date',
      dataIndex: 'dueDate',
      render: (date) => (
        <Text size="xs" color="$primary">
          {format(new Date(date), 'dd MMM, yyyy')}
        </Text>
      ),
    },
    {
      key: 'issueDate',
      title: 'Created',
      dataIndex: 'issueDate',
      render: (date) => (
        <Text size="xs" color="$primary">
          {format(new Date(date), 'dd MMM, yyyy')}
        </Text>
      ),
    },
    {
      key: 'action',
      title: '',
      dataIndex: 'status',
      render: (status, invoice) => (
        <Flex stretchx justify="end">
          <Dropdown placement="bottomRight" menu={getActions(status, invoice)}>
            <Flex stretchx align="center" justify="end">
              <Button appearance="ghost">
                <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
              </Button>
            </Flex>
          </Dropdown>
        </Flex>
      ),
    },
  ]

  const filteredColumns =
    type === 'invoice'
      ? columns
      : columns.filter((column) => column.key !== 'status')

  return (
    <>
      <Table
        loading={loadingInvoices}
        showSearch
        columns={filteredColumns}
        dataSource={invoices?.invoices?.data ?? []}
        actions={
          <Flex gutterX="2">
            {/* <Button
              size="xl"
              appearance="secondary"
              disabled={exporting}
              isLoading={exporting}
              append={<HiArrowCircleUp size="1.3rem" color="#ABB3B9" />}
              onClick={exportInvoices}
            >
              Export
            </Button> */}
            <Button
              size="xl"
              prepend={<HiOutlinePlus color="#fff" />}
              onClick={() => toggleInvoiceDrawer(!showInvoiceDrawer)}
            >
              {isInvoice ? 'Create New Invoice' : 'Create New Offer'}
            </Button>
          </Flex>
        }
        pagination={{
          totalCount: pagination?.totalCount as number,
          currentPage: pagination?.currentPage as number,
          perPage: 10,
          totalPages: pagination?.totalPages as number,
          onPaginationClick: (type) => {
            if (type === 'next') {
              if (page === pagination?.totalPages) return
              setPage(page + 1)
            } else {
              if (page === 1) return
              setPage(page - 1)
            }
          },
        }}
        emptyProps={{
          type: 'cta',
          title: `Create your first ${isInvoice ? 'Invoice' : 'Offer'}`,
          subtitle: `Send a customizable ${
            isInvoice ? 'invoice' : 'offer'
          } to your customers in a few clicks. We'll email them a link to quickly and securely pay online.`,
          action: (
            <Button
              // onClick={toggleInvoiceDrawer}
              onClick={() => toggleInvoiceDrawer(!showInvoiceDrawer)}
              prepend={<HiOutlinePlus color="#fff" />}
              size="xl"
            >
              {isInvoice ? 'Create New Invoice' : 'Create New Offer'}
            </Button>
          ),
        }}
      />
      {/* <InvoiceProvider> */}
      <NewInvoiceDrawer
        visible={showInvoiceDrawer}
        onClose={() => toggleInvoiceDrawer(!showInvoiceDrawer)}
        onSuccess={() => refreshInvoices({ requestPolicy: 'network-only' })}
        type={type}
      />
      {currentInvoice && (
        <EditInvoiceDrawer
          visible={showEditDrawer}
          invoiceData={currentInvoice}
          onClose={toggleEditDrawer}
          onSuccess={() => refreshInvoices({ requestPolicy: 'network-only' })}
          type={type}
        />
      )}
      {/* </InvoiceProvider> */}
      {currentInvoice && (
        <RecordPayment
          invoice={currentInvoice}
          visible={showPaymentDrawer}
          onSuccess={() => refreshInvoices({ requestPolicy: 'network-only' })}
          onClose={togglePaymentDrawer}
        />
      )}
      {currentInvoice && (
        <InvoiceDetail
          downloading={downloading}
          onDownload={() => downloadInvoicePdf(currentInvoice)}
          onSend={() => onSendInvoice(currentInvoice)}
          visible={showPreviewDrawer}
          onClose={toggleShowPreviewDrawer}
          invoice={currentInvoice}
          type={type}
        />
      )}
      {currentInvoice && (
        <SendInvoice
          visible={showSendDrawer}
          onClose={toggleShowSendDrawer}
          invoice={currentInvoice}
          type={type}
        />
      )}
    </>
  )
}
