import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import { Span } from 'components/text/span'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { useDeleteOrganisationMutation } from 'generated/__generated_graphql'
import { useAppProvider } from 'providers/app-provider'
import React from 'react'
import { useForm } from 'react-hook-form'
import { HiTrash } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { extractGraphqlErrors } from 'utils/helpers'
import { z } from 'zod'
import { StyledText } from '../../organisation.styles'

interface IProps {
  visible: boolean
  onClose: () => void
}

export const DeleteOrganisationDrawer: React.FC<IProps> = ({
  visible,
  onClose,
}) => {
  const notify = useToast()
  const { organisation, refreshApp } = useAppProvider()
  const navigate = useNavigate()

  const [, deleteOrganisation] = useDeleteOrganisationMutation()

  const formSchema = z.object({
    name: z.string().refine((name) => name === organisation?.name, {
      message: 'Please enter the exact organisation name',
    }),
  })

  type FormFields = z.infer<typeof formSchema>

  const {
    register,
    reset,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  async function onSubmit() {
    try {
      const response = await deleteOrganisation({
        input: {
          organisationId: Number.parseInt(organisation?.id ?? '0'),
        },
      })

      const error = extractGraphqlErrors(response, 'deleteOrganisation')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      notify({
        content: organisation?.name + ' has been deleted',
        status: 'success',
      })
      refreshApp()
      navigate('/')

      onReset()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function onReset() {
    reset()
    onClose()
  }

  return (
    <Drawer
      title="Delete Organisation"
      titleIcon={<HiTrash size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onReset}
      closable={!isSubmitting}
      maskClosable={!isSubmitting}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={onReset}
        style={{ height: '100%' }}
      >
        <Flex css={{ p: '$5', height: '100%' }} direction="column" gutter="3">
          <Flex direction="column" gutter="3">
            <Text size="xs" color="$primary">
              Are you sure you want to delete this organisation?
            </Text>
            <Flex>
              <StyledText size="xs">
                You and your teammates will immediately lose access and be
                logged out of this Centrum workspace.
              </StyledText>
            </Flex>
            <Input
              {...(register('name') as any)}
              error={errors.name?.message}
              label={
                <Text
                  css={{ mb: '$3', display: 'block', fontFamily: '$bold' }}
                  size="xs"
                  color="$primary"
                >
                  Please type{' '}
                  <Span css={{ color: '$error' }}>{organisation?.name}</Span> to
                  confirm.
                </Text>
              }
              appearance="outline"
              placeholder="Type to delete"
            />
          </Flex>

          <Flex
            gutterX="2"
            css={{ alignItems: 'end', alignSelf: 'end', flexGrow: 1 }}
          >
            <Button
              size="md"
              appearance="secondary"
              type="reset"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              appearance="danger"
              size="md"
              type="submit"
              disabled={!isValid}
              isLoading={isSubmitting}
            >
              Delete Organisation
            </Button>
          </Flex>
        </Flex>
      </form>
    </Drawer>
  )
}
