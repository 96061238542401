import {
  CreatePurchaseInput,
  PurchaseEnum,
  PurchaseItemInput,
} from 'generated/__generated_graphql'
import React, { PropsWithChildren, useReducer } from 'react'
import { id } from 'utils/helpers'

interface PurchaseProvider {
  purchaseState: PurchaseState
  subTotal: number
  discountValue: number
  taxValue: number
  total: number
}

interface IReducerAction {
  type: string
  payload?: any
}

export interface PurchaseItem extends PurchaseItemInput {
  id: string
}

export type PurchaseState = Omit<CreatePurchaseInput, 'purchaseItems'> & {
  purchaseItems: PurchaseItem[]
  discountRate: number
  taxRate: number
}

const initialPurchaseState: PurchaseState = {
  linkedDocumentId: '',
  supplierId: '',
  bankAccountId: '',
  purchaseItems: [],
  message: '',
  taxes: [],
  discounts: [],
  emails: [],
  currency: '566',
  issueDate: new Date(),
  dueDate: new Date(),
  templateId: 'standard',
  isDraft: true,
  uploadId: null,
  discountRate: 0,
  taxRate: 0,
  purchaseType: PurchaseEnum.Purchase,
}

export enum PURCHASE_ACTIONS {
  ADD_ITEM = 'ADD_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM',
  UPDATE_ITEM = 'UPDATE_ITEM',
  UPDATE_PURCHASE = 'UPDATE_STATE',
  INITIALIZE_PURCHASE = 'INITIALIZE_PURCHASE',
  ADD_DISCOUNT = 'ADD_DISCOUNT',
  REMOVE_DISCOUNT = 'REMOVE_DISCOUNT',
  ADD_TAX = 'ADD_TAX',
  REMOVE_TAX = 'REMOVE_TAX',
  SELECT_SUPPLIER = 'SELECT_SUPPLIER',
}

function PurchaseReducer(
  state: PurchaseState,
  action: IReducerAction
): PurchaseState {
  switch (action.type) {
    case PURCHASE_ACTIONS.UPDATE_PURCHASE: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      }
    }
    case PURCHASE_ACTIONS.SELECT_SUPPLIER:
      return {
        ...state,
        supplierId: action.payload.supplierId,
      }

    case PURCHASE_ACTIONS.ADD_TAX: {
      return {
        ...state,
        taxes: [action.payload.tax.id],
        taxRate: action.payload.tax.rate,
      }
    }
    case PURCHASE_ACTIONS.REMOVE_TAX: {
      return {
        ...state,
        taxes: [],
        taxRate: 0,
      }
    }
    case PURCHASE_ACTIONS.ADD_DISCOUNT: {
      return {
        ...state,
        discounts: [action.payload.discount.id],
        discountRate: action.payload.discount.rate,
      }
    }
    case PURCHASE_ACTIONS.REMOVE_DISCOUNT: {
      return {
        ...state,
        discounts: [],
        discountRate: 0,
      }
    }
    case PURCHASE_ACTIONS.ADD_ITEM: {
      return {
        ...state,
        purchaseItems: [
          ...state.purchaseItems,
          { ...action.payload.item, id: id() },
        ],
      }
    }
    case PURCHASE_ACTIONS.REMOVE_ITEM: {
      return {
        ...state,
        purchaseItems: state.purchaseItems.filter(
          (item) => item.id !== action.payload.id
        ),
      }
    }
    case PURCHASE_ACTIONS.UPDATE_ITEM: {
      return {
        ...state,
        purchaseItems: state.purchaseItems.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload.item
          }
          return item
        }),
      }
    }
    case PURCHASE_ACTIONS.INITIALIZE_PURCHASE: {
      return initialPurchaseState
    }
    default:
      return state
  }
}

const PurchaseStateContext = React.createContext<Partial<PurchaseProvider>>({})
const PurchaseDispatchContext =
  React.createContext<React.Dispatch<IReducerAction> | null>(null)

const PurchaseProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [purchaseState, dispatch] = useReducer(
    PurchaseReducer,
    initialPurchaseState
  )

  const subTotal = purchaseState?.purchaseItems?.reduce((acc, item) => {
    return acc + item.quantity * item.unitPrice
  }, 0)

  const discountValue = ((purchaseState.discountRate ?? 0) / 100) * subTotal
  const taxValue = ((purchaseState.taxRate ?? 0) / 100) * subTotal
  const gross = Math.max(0, subTotal! - discountValue!)
  const total = gross + taxValue!

  return (
    <PurchaseStateContext.Provider
      value={{
        purchaseState,
        subTotal,
        total,
        discountValue,
        taxValue,
      }}
    >
      <PurchaseDispatchContext.Provider value={dispatch}>
        {children}
      </PurchaseDispatchContext.Provider>
    </PurchaseStateContext.Provider>
  )
}

export function usePurchaseContext() {
  const state = React.useContext(PurchaseStateContext)
  const dispatch = React.useContext(PurchaseDispatchContext)

  return { state, dispatch }
}

export default PurchaseProvider
