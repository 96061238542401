import { ReactComponent as AccounteerLogo } from 'assets/svg/logo.svg'
import React from 'react'

import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { useVerifyEmailMutation } from 'generated/__generated_graphql'
import { RiErrorWarningFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { setToken } from 'utils/auth'
import { extractGraphqlErrors } from 'utils/helpers'
import {
  StyledCol,
  StyledContent,
  StyledLogoContainer,
  StyledRow,
} from '../auth.styles'
import { Footer } from '../components/footer/footer'

const VerifyEmail: React.FC = () => {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = React.useState('')
  const { id } = useParams()
  const toast = useToast()

  const [{ fetching }, submit] = useVerifyEmailMutation()

  function notifyError(error?: string) {
    toast({
      content: error ?? 'something went wrong',
      status: 'error',
    })
  }

  async function verifyEmail() {
    try {
      const response = await submit({
        input: {
          activationCode: String(id),
        },
      })
      const error = extractGraphqlErrors(response, 'verifyEmail')
      const token = response.data?.verifyEmail?.token
      if (error) {
        notifyError(error)
        setErrorMessage(error)
        return
      }
      if (token) {
        setToken(token)
      }
      navigate('/dashboard')
    } catch (error) {
      notifyError(error as string)
    }
  }

  React.useEffect(() => {
    verifyEmail()
  }, [])

  return (
    <StyledRow gutter={1}>
      <StyledCol span={4}>
        <StyledContent>
          <StyledLogoContainer>
            <AccounteerLogo style={{ width: '7rem' }} />
          </StyledLogoContainer>
          <div>
            {fetching && (
              <Flex gutter={3} align="center">
                <Loader size="sm" />
                <Text variant="h1" size="lg">
                  Verifying email!
                </Text>
              </Flex>
            )}

            {!fetching && (
              <Flex className="flex" gutter={2} align="center">
                <RiErrorWarningFill color="#F15656" size={24} />
                <Text variant="h1" size="lg">
                  {errorMessage}
                </Text>
              </Flex>
            )}

            <Footer
              justify="center"
              align="center"
              direction="column"
              gutterY={1}
            />
          </div>
        </StyledContent>
      </StyledCol>
      {/* <AuthBackground /> */}
    </StyledRow>
  )
}

export default VerifyEmail
