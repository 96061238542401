import { ReactComponent as AccounteerLogo } from 'assets/svg/logo.svg'
import Avatar from 'components/avatar'
import { Box, Flex } from 'components/layout'
import Text from 'components/text/text'
import React from 'react'
import { styled } from 'stitches/stitches.config'

const StyledPage = styled(`div`, {
  // minHeight: '120vh',
  backgroundColor: '$white',
  boxShadow: '$subtle',
})

interface PageProps {
  children: React.ReactNode
  pageNumber: string
  title: string
  subtitle: string
  name: string
}

export const Page = React.forwardRef<HTMLDivElement, PageProps>(
  ({ children, pageNumber, name, title, subtitle, ...props }, ref) => {
    return (
      <StyledPage ref={ref} {...props}>
        <Flex direction="column" css={{ p: '$4', height: '100%' }}>
          <Flex
            justify="between"
            align="center"
            css={{
              pb: '$3',
              borderBottom: '1px solid $border',
            }}
          >
            <Flex gutter={1} align="center">
              <Avatar
                size="medium"
                shape="square"
                background="black"
                title={name}
              />
              <Text color="$black" weight="bold">
                {name}.
              </Text>
            </Flex>
            <Box
              css={{
                p: '$2 $1',
                color: '$white',
                backgroundColor: '$blue',
              }}
            >
              <Text weight="bold" size="xxs">
                {pageNumber}
              </Text>
            </Box>
          </Flex>

          <Flex
            direction="column"
            gutter={2}
            css={{ mt: '$7', borderLeft: '2px solid $blue', pl: '$3' }}
          >
            <Text size="lg" weight="semi">
              {title}
            </Text>
            <Text size="xxs" weight="regular">
              {subtitle}
            </Text>
          </Flex>

          {children}

          <Flex
            justify="between"
            align="center"
            css={{
              mt: '6rem',
              pt: '$2',
              borderTop: '1px solid $border',
            }}
          >
            <Flex gutter={1} align="center" justify="between" stretchx>
              <Text size="xxs" color="$secondary" lineheight="2.5">
                Powered by: <br /> <AccounteerLogo />
              </Text>
              <Text
                size="xxs"
                color="$gray"
                css={{ ml: 'auto', textTransform: 'uppercase' }}
              >
                Confidential for internal use only
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </StyledPage>
    )
  }
)

export function StyledLink({
  children,
  active,
  onClick,
}: {
  children: React.ReactNode
  active: boolean
  onClick: () => void
}) {
  return (
    <Flex
      align="center"
      justify="between"
      gutterX="4"
      onClick={onClick}
      css={{
        cursor: 'pointer',
      }}
    >
      <Text size="xxs" weight="bold" color="$secondary">
        {children}
      </Text>
      <Box
        css={{
          width: '.25rem',
          height: '100%',
          backgroundColor: active ? '$blue' : 'transparent',
          transition: 'background-color .5s ease-in-out',
        }}
      >
        &nbsp;
      </Box>
    </Flex>
  )
}

Page.displayName = 'Page'
