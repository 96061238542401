import { Avatar } from 'components/avatar/avatar'
import Button from 'components/button/button'
import { CollapsibleSection } from 'components/drawer/collapsible-section'
import { Drawer } from 'components/drawer/drawer'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import PhoneNumberInput from 'components/phone-number-input'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import { motion } from 'framer-motion'
import {
  CustomerContact,
  UpdateCustomerInput,
  useBanksQuery,
  useCountriesQuery,
  useCreateCustomerMutation,
  useCreateSupplierMutation,
  useCurrenciesQuery,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import React, { useState } from 'react'
import { HiPlus, HiUserAdd, HiX } from 'react-icons/hi'
import { countries } from 'utils/countries'
import { extractGraphqlErrors } from 'utils/helpers'
import { omit } from 'utils/object'

interface AddContactsDrawerProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (val?: any) => void
  refetchCustomers: () => void
  type?: 'customer' | 'supplier'
}

export const AddContactsDrawer: React.FC<AddContactsDrawerProps> = (props) => {
  const {
    visible,
    onClose,
    onSuccess,
    refetchCustomers,
    type = 'customer',
  } = props
  const [showBankDetails, setShowBankDetails] = useState(true)
  const [showCustomerDetails, setShowCustomerDetails] = useState(true)
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false)
  const [customerContacts, setCustomerContacts] = useState<CustomerContact[]>(
    []
  )
  const [country, setCountry] = React.useState<string>('')

  const [payload, setPayload] = useState<Omit<UpdateCustomerInput, 'id'>>()
  const toast = useToast()

  const { register, values, setInputValue, errors, formIsComplete, clearForm } =
    useForm({
      fields: {
        name: '',
        tin: '',
        country: '',
        address: '',
        city: '',
        zipCode: '',
        accountNumber: '',
        routingNumber: '',
        bankName: '',
        currency: '',
        customerName: '',
        customerEmail: '',
        phone: '',

        beneficiaryName: '',
        beneficiaryAddress: '',
        bankAddress: '',
        sortCode: '',
      },
      optional: [
        'routingNumber',
        'beneficiaryAddress',
        'customerName',
        'customerEmail',
        'phone',
        'tin',
      ],
    })

  const [{ data: countriesData }] = useCountriesQuery({
    variables: {
      supported: false,
      unsupported: false,
    },
  })

  const countriesMap =
    countriesData?.countries.map((country) => {
      return {
        label: `${country.emojiFlag} ${country.name}`,
        value: country.code,
      }
    }) ?? []

  const [{ data: banksData }] = useBanksQuery({
    variables: {
      countryCode: country,
    },
    pause: !country,
  })
  const banksMap =
    banksData?.banks.map((bank) => {
      return {
        label: bank.name,
        value: bank.name,
      }
    }) ?? []

  const [{ data: currenciesData }] = useCurrenciesQuery({
    variables: {
      supportedPayout: true,
    },
  })

  const currenciesMap =
    currenciesData?.currencies.map((currency) => {
      return {
        label: currency.name,
        value: currency.id,
        code: currency.code,
        symbol: currency.symbol,
      }
    }) ?? []

  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  const [{ fetching }, submit] = useCreateCustomerMutation()
  const [{ fetching: creatingSupplier }, createSupplier] =
    useCreateSupplierMutation()

  async function handlePayload() {
    const payload = {
      name: values.name,
      tin: values.tin ? values.tin : null,
      country: values.country,
      address: values.address,
      city: values.city,
      zipCode: values.zipCode,
      ...(Object.keys(values.accountNumber).length > 0 && {
        bankAccount: {
          accountNumber: values.accountNumber,
          name: values.bankName,
          currencyId: values.currency,
          routingNumber: values.routingNumber,
          beneficiaryName: values.beneficiaryName,
          beneficiaryAddress: values.beneficiaryAddress,
          bankAddress: values.bankAddress,
          sortCode: values.sortCode,
        },
      }),
      ...(Object.keys(values.customerName).length > 0 && {
        customerContacts: [
          {
            name: values.customerName,
            email: values.customerEmail,
            phone: values.phone,
          },
        ],
      }),
    }
    setPayload(payload)
    return payload
  }

  function handleAddCustomerContacts() {
    return setCustomerContacts([
      ...customerContacts,
      {
        id: '',
        name: '',
        email: '',
        phone: '',
      },
    ])
  }

  async function handleCreateContact() {
    setSubmitButtonPressed(true)
    try {
      const payload = await handlePayload()
      const response = await submit({ input: payload })
      const error = extractGraphqlErrors(response, 'createCustomer')

      if (error) {
        notify(error, 'error')
        return
      }
      notify('Contact created successfully', 'success')
      resetAndClose()
      onSuccess?.(response.data?.createCustomer?.customer)
      refetchCustomers()
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  async function handleCreateSupplier() {
    setSubmitButtonPressed(true)
    try {
      let payload: any = await handlePayload()
      if (type === 'supplier') {
        payload.supplierContacts = payload.customerContacts
        payload = omit(payload, ['customerContacts'])
      }
      const response = await createSupplier({ input: payload })
      const error = extractGraphqlErrors(response, 'createSupplier')

      if (error) {
        notify(error, 'error')
        return
      }
      notify('Supplier created successfully', 'success')
      resetAndClose()
      onSuccess?.(response.data?.createSupplier?.supplier)
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  function resetAndClose() {
    clearForm()
    onClose()
  }

  return (
    <Drawer
      title="Add supplier"
      titleIcon={<HiUserAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={() => {
        setShowBankDetails(true)
        setShowCustomerDetails(true)
        resetAndClose()
      }}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="md"
            isLoading={fetching || creatingSupplier}
            disabled={!formIsComplete}
            onClick={
              type === 'customer' ? handleCreateContact : handleCreateSupplier
            }
          >
            Add supplier
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="3">
        <CollapsibleSection borders title="Organisation Information">
          <Flex direction="column" gutter="4">
            <Input
              required
              label="Organisation Name"
              placeholder="Enter your organisation's name"
              {...register('name')}
              error={errors.name}
            />
            <Input
              label="TIN/EIN"
              placeholder="Enter your organisation's TIN/EIN"
              {...register('tin')}
              error={errors.tin}
            />
            {/* <Select
              search
              required
              label="Country"
              searchPlaceholder="Search for a country"
              placeholder="Choose a country"
              options={countriesMap}
              name="country"
              onChange={(value) => {
                setInputValue('country', value as string)
                setCountry(value as string)
              }}
              value={values.country}
              error={errors.country}
            /> */}
            <TypeAheadSelect
              required
              label="Country"
              placeholder="Search for a country"
              options={countriesMap}
              value={values.country}
              onChange={(value) => {
                setInputValue('country', value as string)
                setCountry(value as string)
              }}
              error={errors.country}
            />
            <Input
              required
              label="Address"
              placeholder="Enter address"
              {...register('address')}
              error={errors.address}
            />
            <Input
              required
              label="City"
              placeholder="Enter city"
              {...register('city')}
              error={errors.city}
            />
            <Input
              required
              label="Zip code"
              placeholder="Enter zip code"
              {...register('zipCode')}
              error={errors.zipCode}
            />
          </Flex>
        </CollapsibleSection>

        {showBankDetails && (
          <CollapsibleSection borders title="Banking Details">
            <Flex direction="column" gutter="4">
              <TypeAheadSelect
                required
                label="Currency"
                placeholder="Search for a currency"
                options={currenciesMap}
                value={values.currency}
                onChange={(value) => {
                  setInputValue('currency', value as string)
                }}
                error={errors.currency}
                renderValue={(option) => (
                  <Flex align="center" gutter="2">
                    <Avatar size="medium" title={option.symbol} />
                    <Text size="xs">{option.label}</Text>
                  </Flex>
                )}
              />

              <Input
                required
                label="Beneficiary Name"
                placeholder="Full name of the account holder"
                name="beneficiaryName"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue('beneficiaryName', e.target.value)
                }
                error={errors.beneficiaryName}
              />
              <Input
                label="Beneficiary Address"
                placeholder="Full address of the account holder."
                name="beneficiaryAddress"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue('beneficiaryAddress', e.target.value)
                }
                error={errors.beneficiaryAddress}
              />
              <Input
                required
                label="Account Number"
                placeholder="Bank account number."
                name="accountNumber"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue('accountNumber', e.target.value)
                }
                error={errors.accountNumber}
              />
              <Input
                label="Routing Number"
                placeholder="ABA routing number of the beneficiary’s bank."
                name="routingNumber"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue('routingNumber', e.target.value)
                }
                error={errors.routingNumber}
              />
              <Input
                required
                label="Bank name"
                placeholder="Name of the beneficiary’s bank."
                name="bankName"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue('bankName', e.target.value)
                }
                error={errors.bankName}
              />
              <Input
                required
                label="Bank Address"
                placeholder="Full address of the beneficiary’s bank."
                name="bankAddress"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue('bankAddress', e.target.value)
                }
                error={errors.bankAddress}
              />
              <Input
                label="SWIFT/BIC Code"
                placeholder="International identifier for the bank (if applicable for international transfers)."
                name="sortCode"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue('sortCode', e.target.value)
                }
                error={errors.sortCode}
              />

              {/* <TypeAheadSelect
                required
                label="Bank"
                placeholder="Search for a bank"
                options={banksMap}
                value={values.bankName}
                onChange={(value) => {
                  setInputValue('bankName', value as string)
                }}
                error={errors.bankName}
              /> */}
            </Flex>
          </CollapsibleSection>
        )}

        {showCustomerDetails && (
          <CollapsibleSection title="User Contacts" borders>
            <Flex direction="column" gutter="4">
              <Input
                label="Name"
                placeholder="Jane Doe"
                {...register('customerName')}
                error={errors.customerName}
              />
              <Input
                label="Email"
                placeholder="someone@email.com"
                {...register('customerEmail')}
                error={errors.customerEmail}
              />
              <PhoneNumberInput
                label="Phone Number"
                name="phone"
                onChange={(phoneNumberData) => {
                  setInputValue(
                    'phone',
                    `${phoneNumberData.dialingCode} ${phoneNumberData.phoneNumber}`
                  )
                }}
                error={errors.phone}
                countries={countries}
              />
            </Flex>
            {customerContacts.map((contact, index) => (
              <CustomerContacts
                key={index}
                payload={payload}
                submitButtonPressed={submitButtonPressed}
                setSubmitButtonPressed={setSubmitButtonPressed}
              />
            ))}
            <Button
              disabled={!formIsComplete}
              onClick={handleAddCustomerContacts}
            >
              <HiPlus /> Add another contact
            </Button>
          </CollapsibleSection>
        )}
      </Flex>
    </Drawer>
  )
}

type CustomerContactsProps = {
  payload: Omit<UpdateCustomerInput, 'id'> | undefined
  submitButtonPressed: boolean
  setSubmitButtonPressed: (value: boolean) => void
}

function CustomerContacts({
  payload,
  submitButtonPressed,
  setSubmitButtonPressed,
}: CustomerContactsProps) {
  const { register, values, setInputValue, errors, clearForm } = useForm({
    fields: {
      name: '',
      email: '',
      phone: '',
    },
  })

  React.useEffect(() => {
    if (submitButtonPressed) {
      updateContactPayload()
    }
  }, [submitButtonPressed])

  function updateContactPayload() {
    const emailExists = payload?.customerContacts?.find(
      (contact) => contact.email === values.email
    )

    if (!emailExists) {
      payload?.customerContacts?.push({
        name: values.name,
        email: values.email,
        phone: values.phone,
      })
    } else {
      const index = payload?.customerContacts?.findIndex(
        (contact) => contact.email === values.email
      )
      if (index !== undefined && payload?.customerContacts) {
        payload.customerContacts[index] = {
          name: values.name,
          email: values.email,
          phone: values.phone,
        }
      }
    }
    setSubmitButtonPressed(false)
    clearForm()
  }

  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 20,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
    >
      <Flex direction="column" gutter="4">
        <Input
          label="Name"
          placeholder="Jane Doe"
          {...register('name')}
          error={errors.name}
        />
        <Input
          label="Email"
          placeholder="someone@email.com"
          {...register('email')}
          error={errors.email}
        />
        <PhoneNumberInput
          label="Phone Number"
          required
          onChange={(phoneNumberData) => {
            setInputValue(
              'phone',
              `${phoneNumberData.dialingCode} ${phoneNumberData.phoneNumber}`
            )
          }}
          error={errors.phone}
          countries={countries}
        />
      </Flex>
    </motion.div>
  )
}
