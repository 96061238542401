import Button from 'components/button/button'
import Flex from 'components/layout/flex'
import { motion } from 'framer-motion'
import { styled } from 'stitches/stitches.config'

export const StyledDrawerBackdrop = styled(motion.div, {
  position: 'fixed',
  inset: 0,
  pointerEvents: 'auto',
  backgroundColor: '$backdropBg',
  zIndex: '10',
})

export const ZIndexDrawerWrapper = styled('div', {
  zIndex: '$max',
})

export const StyledDrawer = styled(motion.div, {
  maxHeight: '100%',
  minWidth: '44rem',
  backgroundColor: '$white',
  borderRadius: '1rem',
  position: 'fixed',
  inset: 0,
  margin: '1.5rem',
  transform: 'translateX(-120%)',
  boxShadow: '$deep',
  zIndex: '$max',

  defaultVariants: {
    size: 'normal',
  },

  variants: {
    size: {
      normal: {
        width: '33vw',
      },

      large: {
        width: '92rem',
      },
    },

    fullHeight: {
      false: {
        height: 'fit-content',
      },
    },
  },
})

export const StyledHeader = styled(Flex, {
  padding: '2.2rem 2.4rem',
  borderBottom: '0.1rem solid $border',
})

export const StyledButton = styled(Button, {
  maxWidth: 'unset',
  padding: '0 !important',
  borderRadius: '100%',
  height: 'fit-content !important',
  boxShadow: 'none !important',
  marginLeft: 'auto',
})

export const StyledContent = styled('div', {
  flexGrow: 1,
  overflow: 'auto',
})

export const StyledFooter = styled(Flex, {
  padding: '1.6rem 2.4rem',
  marginTop: 'auto',
  justifySelf: 'flex-end',
})

export const StyledDrawerSection = styled(Flex, {
  padding: '3.2rem 2.4rem',

  '&:not(:last-of-type)': {
    borderBottom: '0.1rem solid $border',
  },

  variants: {
    borders: {
      false: {
        '&:not(:last-of-type)': {
          borderBottom: 'none',
        },
      },
    },
  },
})

export const StyledDrawerSectionHead = styled(Flex, {
  cursor: 'pointer',
})

export const StyledDrawerPopup = styled(Flex, {
  // height: 'unset'
  // maxHeight: '60rem',
  height: 'min-content',
  minWidth: '44rem',
  backgroundColor: '$white',
  borderRadius: '1rem',
  position: 'fixed',
  inset: 0,
  display: 'none',
  boxShadow: '$deep',

  variants: {
    openPopup: {
      true: {
        zIndex: 2,
        display: 'block',
      },
    },
  },
})
