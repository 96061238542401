import { ReactComponent as AccounteerLogo } from 'assets/svg/logo.svg'
import Button from 'components/button/button'
import { Box, Flex } from 'components/layout'
import Page from 'components/page/page'
import { StyledLink } from 'components/text/link'
import { Text } from 'components/text/text'
import React, { useEffect, useState } from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import { HiLogout } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import { setToken } from 'utils/auth'
// import ConnectAccount from './connect-account'
import { Header, Logout } from './onboarding.styles'
// import SelectPlan from './select-plan'
import SetupBusiness from './setup-business'
// import SelectPlan from './select-plan'
// import SubscribeToPlan from './subscribe-to-plan'
// import SubscribeToPlan from './subscribe-to-plan'

type OnboardingStatus = 'business' | 'account' | 'plans' | 'subscribe'
// type OnboardingStatus = 'business' | 'account'

const Onboarding: React.FC = () => {
  const [status, setStatus] = useState<OnboardingStatus>('business')
  // const navigate = useNavigate()

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const queryStatus = query.get('status') as OnboardingStatus
    if (queryStatus) {
      setStatus(queryStatus)
    }
  }, [])

  return (
    <Page>
      <Header>
        <Flex justify={'center'} align={'center'} gutter={'2'}>
          <GoBackButton />
          <AccounteerLogo style={{ width: '7rem' }} />
        </Flex>
        <Logout css={{}} align="center" justify="start" gutter="2">
          <HiLogout size="2rem" color="#ABB3B9" />
          <StyledLink to="/" onClick={() => setToken(null)}>
            <Text size="xs" weight="semi" color="$secondary">
              Log out
            </Text>
          </StyledLink>
        </Logout>
      </Header>

      <Box>
        {status === 'business' && (
          <SetupBusiness
            onProceed={() => {
              window.location.replace(`${window.location.origin}/dashboard`)
            }}
            // onProceed={() => navigate(`/onboarding?status=account`)}
          />
        )}
        {/* {status === 'account' && (
          <ConnectAccount
            onProceed={() => {
              window.location.replace(`${window.location.origin}/dashboard`)
            }}
          />
        )} */}
        {/* {status === 'plans' && <SelectPlan />}
        {status === 'subscribe' && <SubscribeToPlan />} */}
      </Box>
    </Page>
  )
}

export default Onboarding

const GoBackButton = () => {
  const { search } = useLocation()

  const queryStatus = (
    search.includes('&')
      ? search.split('=').at(1)?.split('&').at(0)
      : search.split('=').at(-1)
  ) as OnboardingStatus

  const previousPageUrls: { [K in OnboardingStatus]: string } = {
    business: '',
    account: '/onboarding?status=business',
    plans: '/onboarding?status=account',
    subscribe: '/onboarding?status=plans',
  }

  if (queryStatus === 'business') return <></>

  return (
    <Button
      appearance={'ghost'}
      css={{ width: 'fit-content' }}
      as="a"
      // @ts-ignore
      href={previousPageUrls[queryStatus]}
    >
      <BiChevronLeft size={'3rem'} color="#848C96" />
    </Button>
  )
}
