import { Avatar } from 'components/avatar/avatar'
import Button from 'components/button/button'
import DateInput from 'components/date-input/date-input'
import { CollapsibleSection } from 'components/drawer/collapsible-section'
import { Drawer } from 'components/drawer/drawer'
import { Dropdown } from 'components/dropdown/dropdown'
import { InputLabel } from 'components/input'
import Input from 'components/input/input'
import { Flex } from 'components/layout'
import { Text } from 'components/text/text'
import Textarea from 'components/textarea/textarea'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import {
  BankAccount,
  PurchaseEnum,
  PurchaseItemInput,
  Supplier,
  useCreatePurchaseMutation,
  useFinancialAccountsQuery,
  useSuppliersQuery,
} from 'generated/__generated_graphql'
import useDisclosure from 'hooks/useDisclosure'
import { AddContactsDrawer } from 'pages/contacts/components/add-contacts-drawer'
import { AddAccountDrawer } from 'pages/financial/components/add-account-drawer'
import {
  BorderedSection,
  CustomerBox,
  StyledItemsDropdownHeaderAction,
  StyledItemsWrapper,
} from 'pages/sales/sales.styles'
import { useAppProvider } from 'providers/app-provider'
import React, { useState } from 'react'
import {
  HiCalendar,
  HiChevronDown,
  HiDocumentAdd,
  HiOutlineSearch,
  HiPlusCircle,
  HiUserAdd,
  HiX,
} from 'react-icons/hi'
import { extractGraphqlErrors, formatLocaleDate } from 'utils/helpers'
import { omit } from 'utils/object'
import {
  PurchaseState,
  PURCHASE_ACTIONS,
  usePurchaseContext,
} from '../providers/purchase-provider'
import { ExpenseItems } from './expense-items'
import { FiAlertCircle } from 'react-icons/fi'

interface IProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (values?: any) => void
  type?: PurchaseEnum
}

export const NewExpenseDrawer: React.FC<IProps> = (props) => {
  const notify = useToast()
  const { visible, onClose, onSuccess, type = PurchaseEnum.Purchase } = props

  const [{ data: suppliers }, refetchSuppliers] = useSuppliersQuery({
    variables: { pagination: {} },
  })

  const [{ data: accounts }, refreshAccount] = useFinancialAccountsQuery({
    variables: { pagination: {} },
  })

  const [supplier, setSupplier] = useState<Supplier>()

  const { isOpen: showSuppliersModal, toggle: toggleSuppliersModal } =
    useDisclosure()
  const { isOpen: showAccount, toggle: toggleShowAccount } = useDisclosure()

  const [{ fetching: creatingPurchase }, createPurchase] =
    useCreatePurchaseMutation()

  const [openDrawerPopup, setOpenDrawerPopup] = React.useState(false)

  const { organisation } = useAppProvider()

  const {
    dispatch,
    state: { purchaseState },
  } = usePurchaseContext()

  async function onSave(isDraft: boolean) {
    if (!purchaseState) {
      return
    }
    const payload = {
      ...(omit(purchaseState, [
        'discountRate',
        'taxRate',
        'dueDate',
        'issueDate',
      ]) as PurchaseState),
      templateId: null,
      isDraft,
      purchaseType:
        type === 'purchase'
          ? PurchaseEnum.Purchase
          : PurchaseEnum.PurchaseOrder,
      purchaseItems: purchaseState.purchaseItems.map(
        (item) => omit(item, ['id']) as PurchaseItemInput
      ),
      dueDate: formatLocaleDate(purchaseState.dueDate),
      issueDate: formatLocaleDate(purchaseState.issueDate),
    }

    try {
      const response = await createPurchase({ input: payload })
      const error = extractGraphqlErrors(response, 'createPurchase')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Purchase saved successfully',
        position: 'top',
        status: 'success',
      })
      onSuccess?.()
      onDismiss()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function onDismiss() {
    setSupplier(undefined)
    dispatch?.({ type: PURCHASE_ACTIONS.INITIALIZE_PURCHASE })
    onClose()
  }

  const currency = organisation?.currency.symbol

  const canSave =
    purchaseState?.supplierId !== '' &&
    purchaseState?.issueDate &&
    purchaseState?.dueDate &&
    purchaseState?.purchaseItems.length > 0

  const financialAccounts = accounts?.financialAccounts?.data.filter(
    (account) => account.id !== '1'
  )

  const isBill = type === PurchaseEnum.Purchase

  return (
    <Drawer
      size="large"
      title={type === PurchaseEnum.Purchase ? 'New Bill' : 'New Purchase Order'}
      titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onDismiss}
      openPopup={openDrawerPopup}
      onPopupClose={() => setOpenDrawerPopup(!openDrawerPopup)}
      popupTitle="Payment details"
      popupContent={
        <PaymentDetails
          onSuccess={() => {
            setOpenDrawerPopup(false)
            onDismiss()
            notify({
              content: 'Thank you for your payment',
              position: 'top',
              status: 'success',
            })
          }}
        />
      }
      footer={
        <Flex justify="between" stretchx gutterX="2">
          <Button appearance="secondary" onClick={onDismiss}>
            Cancel
          </Button>
          {isBill ? (
            <Button
              onClick={() => setOpenDrawerPopup(true)}
              disabled={creatingPurchase || !canSave}
            >
              Proceed to payment
            </Button>
          ) : (
            <>
              <Button
                appearance="secondary"
                css={{ ml: 'auto' }}
                disabled={creatingPurchase || !canSave}
                onClick={() => onSave(true)}
              >
                Save
              </Button>
              <Button
                onClick={() => onSave(false)}
                disabled={creatingPurchase || !canSave}
                isLoading={creatingPurchase}
              >
                Save & Approve
              </Button>
            </>
          )}
        </Flex>
      }
    >
      <Flex>
        <BorderedSection sideBorder direction="column" gutterY="5">
          <Flex direction="column" gutterY="5">
            <AddContactsDrawer
              visible={showSuppliersModal}
              onClose={toggleSuppliersModal}
              type="supplier"
              onSuccess={(supplier) => {
                setSupplier(supplier as Supplier)
                dispatch?.({
                  type: PURCHASE_ACTIONS.SELECT_SUPPLIER,
                  payload: {
                    supplierId: supplier?.id,
                  },
                })
              }}
              refetchCustomers={() =>
                refetchSuppliers({ requestPolicy: 'network-only' })
              }
            />

            <Flex
              align="center"
              gutter={2}
              justify="between"
              css={{
                '& > div': {
                  width: '50%',
                },
              }}
            >
              {supplier ? (
                <Flex direction="column">
                  <InputLabel required> Supplier </InputLabel>
                  <CustomerBox justify="between" align="center">
                    <Flex align="center" gutterX="2">
                      <Avatar size="medium" title={supplier.name} />
                      <Flex direction="column">
                        <Text size="xs" color="$primary">
                          {supplier.name}
                        </Text>
                      </Flex>
                    </Flex>
                    <Button
                      appearance="ghost"
                      onClick={() => setSupplier(undefined)}
                    >
                      <HiX role="button" color="#ABB3B9" />
                    </Button>
                  </CustomerBox>
                </Flex>
              ) : (
                <Dropdown
                  fullWidth
                  menu={suppliers?.suppliers?.data?.map((supplier) => {
                    return {
                      label: (
                        <Text size="xs" color="$primary">
                          {supplier.name}
                        </Text>
                      ),
                      onClick: () => {
                        setSupplier(supplier as Supplier)
                        dispatch?.({
                          type: PURCHASE_ACTIONS.SELECT_SUPPLIER,
                          payload: {
                            supplierId: supplier.id,
                          },
                        })
                      },
                    }
                  })}
                  header={
                    <Button appearance="ghost" onClick={toggleSuppliersModal}>
                      <StyledItemsDropdownHeaderAction
                        align="center"
                        gutterX="2"
                      >
                        <HiUserAdd color="#ABB3B9" />
                        <Text size="xs" color="$secondary">
                          Add new supplier
                        </Text>
                      </StyledItemsDropdownHeaderAction>
                    </Button>
                  }
                >
                  <Input
                    required
                    prepend={<HiOutlineSearch color="#ABB3B9" />}
                    label="Supplier"
                    placeholder="Find or add a supplier"
                  />
                </Dropdown>
              )}
              {isBill && (
                <TypeAheadSelect
                  required
                  label="Linked document"
                  placeholder="Select purchase order"
                  options={financialAccounts}
                  valueKey="id"
                  labelKey="name"
                  renderOption={(value: BankAccount) => (
                    <Flex align="center" gutterX="2" css={{ py: '1.2rem' }}>
                      <Text size="xs">
                        {value.title}({value.name ?? value.routingNumber})
                      </Text>
                    </Flex>
                  )}
                  renderValue={(value: BankAccount) => (
                    <Flex align="center" gutterX="2" css={{ py: '1.2rem' }}>
                      <Text size="xs">
                        {value.title}({value.name ?? value.routingNumber})
                      </Text>
                    </Flex>
                  )}
                  noOptionsMessage={() => (
                    <Flex direction="column">
                      <Button appearance="ghost" onClick={toggleShowAccount}>
                        <HiPlusCircle color="#398AFA" />
                        <Text color="$blue">Link an account</Text>
                      </Button>
                    </Flex>
                  )}
                  onChange={(value) => {
                    dispatch?.({
                      type: PURCHASE_ACTIONS.UPDATE_PURCHASE,
                      payload: {
                        field: 'bankAccountId',
                        value: value,
                      },
                    })
                  }}
                />
              )}
            </Flex>

            <Flex
              align="center"
              gutter={2}
              justify="between"
              css={{
                '& > div': {
                  width: '50%',
                },
              }}
            >
              <DateInput
                required
                prepend={<HiCalendar color="#ABB3B9" />}
                append={<HiChevronDown size="1.9rem" color="#ABB3B9" />}
                label="Issue Date"
                placeholder="Select date"
                dateFormat="MMM dd, yyyy"
                onChange={(e) => {
                  dispatch?.({
                    type: PURCHASE_ACTIONS.UPDATE_PURCHASE,
                    payload: {
                      field: 'issueDate',
                      value: new Date(e.target.value),
                    },
                  })
                }}
              />

              <DateInput
                required
                prepend={<HiCalendar color="#ABB3B9" />}
                append={<HiChevronDown size="1.9rem" color="#ABB3B9" />}
                label="Due date"
                placeholder="Select date"
                dateFormat="MMM dd, yyyy"
                onChange={(e) => {
                  dispatch?.({
                    type: PURCHASE_ACTIONS.UPDATE_PURCHASE,
                    payload: {
                      field: 'dueDate',
                      value: new Date(e.target.value),
                    },
                  })
                }}
              />
            </Flex>
          </Flex>
        </BorderedSection>
      </Flex>

      <Flex
        direction={'column'}
        gutter={'2'}
        justify={'start'}
        align={'start'}
        css={{
          width: '100%',
          padding: '3.2rem 2.4rem',
          borderBottom: '0.1rem solid $border',
          [`& ${StyledItemsWrapper}`]: {
            width: '100%',
          },
        }}
      >
        <InputLabel required>Items</InputLabel>
        <ExpenseItems currency={currency} />
      </Flex>

      <CollapsibleSection title="Additional Options" borders={false} collapse>
        <Flex gutterY="5" direction="column">
          <Textarea
            label="Remark"
            placeholder="Type something here ..."
            onChange={(e) => {
              dispatch?.({
                type: PURCHASE_ACTIONS.UPDATE_PURCHASE,
                payload: {
                  field: 'message',
                  value: e.target.value,
                },
              })
            }}
          />
        </Flex>
      </CollapsibleSection>
      <AddAccountDrawer
        visible={showAccount}
        onClose={toggleShowAccount}
        onAccountAddSuccess={() =>
          refreshAccount({ requestPolicy: 'network-only' })
        }
      />
    </Drawer>
  )
}

export function PaymentDetails({
  onSuccess,
  setOpenDrawerPopup,
  toastPaymentMade,
  onDismiss,
}: // setPayBillDrawer,
any) {
  const [currency, setCurrency] = React.useState<{
    id: number
    currency: string
    bankName: string
    accountNumber: string
    sortCode: string
    address: string
    amountToPay: string
  } | null>(null)

  const currencies = [
    {
      id: 1,
      currency: 'Nigerian Naira',
      bankName: 'Access Bank',
      accountNumber: '0922748274',
      sortCode: '123456',
      address: '54 Ikoyi Bordillon, Lagos, NG',
      amountToPay: 'N 4,000,565.45',
    },
    {
      id: 2,
      currency: 'United States Dollar',
      bankName: 'JP Morgan',
      accountNumber: '9384845545',
      sortCode: 'BJSF98',
      address: '01 Seatlle, CA, USA',
      amountToPay: '$ 3,500.45',
    },
  ]

  return (
    <Flex direction="column" gutterY="4" css={{ padding: '$4 20rem' }}>
      <Input label="Bill refrence" value="BILL-0002" required />
      <TypeAheadSelect
        required
        label="Currency"
        placeholder="Select the currency you want to pay with"
        options={currencies}
        valueKey="id"
        labelKey="currency"
        onChange={(value) => {
          const selectedCurrency = currencies.find(
            (currency) => currency.id === value
          )
          setCurrency(selectedCurrency || null)
        }}
      />

      {currency && (
        <Flex
          direction="column"
          gutterY={5}
          css={{
            borderTop: '1px solid #eee',
            pt: '2rem',
          }}
        >
          <Input label="Amount to pay" value={currency.amountToPay} />
          <InputLabel>Pay to the following account:</InputLabel>
          <Text
            css={{
              p: '$3',
              backgroundColor: '#F8F8F8',
              border: '1px solid #D3D3D3',
              fontSize: '1.3rem',
              lineHeight: '3rem',
              color: '#888',
              mt: '-2rem',
              borderRadius: '$3',
            }}
          >
            Bank Name: {currency.bankName} <br />
            Account Number: {currency.accountNumber} <br />
            Sort Code: {currency.sortCode} <br />
            Bank Address: {currency.address}
          </Text>
          <Flex
            align="center"
            gutterX="1"
            css={{
              mt: '-1rem',
              p: '$1 $2',
              background: '#FFEAC7',
              borderRadius: '$2',
            }}
          >
            <FiAlertCircle size="1.2rem" color="#957846" />
            <Text
              css={{
                fontSize: '1.2rem',
                color: '#957846',
              }}
            >
              Please include the bill reference number in the payment narration
              and ensure that you pay exactly {currency.amountToPay}
            </Text>
          </Flex>
        </Flex>
      )}

      <Flex justify="between" stretchx gutterX="2" css={{ mt: '4rem' }}>
        <Button
          appearance="secondary"
          onClick={() => setOpenDrawerPopup(false)}
        >
          Cancel
        </Button>

        <Button onClick={onSuccess} disabled={!currency}>
          I've made this payment
        </Button>
      </Flex>
    </Flex>
  )
}
