import { Avatar } from 'components/avatar/avatar'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import useOutsideClick from 'hooks/useOutsideClick'
import { useAppProvider } from 'providers/app-provider'
import React from 'react'
import { BsChevronExpand } from 'react-icons/bs'
import { HiCheckCircle, HiCog, HiLogout, HiSupport } from 'react-icons/hi'
import { setToken } from 'utils/auth'
import { sliceName } from 'utils/helpers'
import {
  StyledContent,
  StyledDropdown,
  StyledLink,
  StyledWrapper,
} from './account-switcher.styles'

interface AccountSwitcherProps {}

const AccountSwitcher: React.FC<AccountSwitcherProps> = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { organisation } = useAppProvider()

  const containerRef = React.useRef(null)
  useOutsideClick({
    ref: containerRef,
    isOpen,
    callback: () => {
      setIsOpen(false)
    },
  })

  const { name } = organisation ?? {}

  const orgName = sliceName(name ?? 'Unknown', 21)

  return (
    <StyledWrapper ref={containerRef}>
      <StyledDropdown onClick={() => setIsOpen(!isOpen)} role="button">
        <Flex css={{ cursor: 'pointer' }} align="center" justify='between' gutter="2">
          <Flex align="center" justify="center" gutter="2">
            <Avatar size="medium" shape="square" title={orgName} />
            <Flex direction="column">
              <Text size="xs" weight="bold" color="$white">
                {orgName}
              </Text>
            </Flex>
          </Flex>
          <Flex align="center" justify="center" gutter="1">
            <BsChevronExpand size={'1.5rem'} color="white" />
          </Flex>
        </Flex>
      </StyledDropdown>
      <StyledContent open={isOpen}>
        <Flex
          direction="column"
          gutter="1"
          css={{
            width: '100%',
            borderBottom: '.1rem solid $border',
            padding: '$3',
          }}
        >
          <Flex
            align="center"
            justify="start"
            gutter="2"
            css={{
              width: '100%',
              cursor: 'pointer',
            }}
            className="hover"
          >
            <Avatar shape="square" title={orgName} />
            <Flex direction="column" gutter="1">
              <Text size="xs" weight="bold" color="$primary">
                {orgName}
              </Text>
              <Text size="xs" color="$secondary">
                {organisation?.plan?.name}
              </Text>
            </Flex>
            <Box css={{ justifySelf: 'flex-end', ml: 'auto' }}>
              <HiCheckCircle size="2rem" color="#8EB0DF" />
            </Box>
          </Flex>
        </Flex>


        <Flex
          css={{
            padding: '$2',
            m: '$2 $4',
            cursor: 'pointer',
            transition: 'all .2s ease-in-out',

            '&:hover': {
              background: '$highlightBg',
              borderRadius: '$2',
            },
          }}
          align="center"
          justify="start"
          gutter="2"
          onClick={() => {
            // @ts-expect-error
            window.fcWidget.open()
          }}
        >
          <HiSupport size="2rem" color="#ABB3B9" />
          <Text size="xs" weight="semi" color="$secondary">
            Help & Support
          </Text>
        </Flex>

        <StyledLink
          css={{
            display: 'flex',
            padding: '$2',
            m: '$2 $4',
            cursor: 'pointer',
            transition: 'all .2s ease-in-out',
            alignContent: 'center',
            justifyContent: 'start',
            gap: '$2',
            '&:hover': {
              background: '$highlightBg',
              borderRadius: '$2',
            },
          }}
          to="/dashboard/settings/account/profile"
        >
          <HiCog size="2rem" color="#ABB3B9" />

          <Text size="xs" weight="semi" color="$secondary">
            Settings
          </Text>
        </StyledLink>

        <Flex
          css={{
            padding: '$2',
            m: '$2 $4',
            cursor: 'pointer',
            transition: 'all .2s ease-in-out',

            '&:hover': {
              background: '$highlightBg',
              borderRadius: '$2',
            },
          }}
          align="center"
          justify="start"
          gutter="2"
          as="a"
          href="/"
          onClick={() => {
            setToken(null)
            localStorage.removeItem('lastPath')
            window.location.reload()
          }}
        >
          <HiLogout size="2rem" color="#ABB3B9" />
          <StyledLink to="/">
            <Text size="xs" weight="semi" color="$secondary">
              Log out
            </Text>
          </StyledLink>
        </Flex>
      </StyledContent>
    </StyledWrapper>
  )
}

export default AccountSwitcher