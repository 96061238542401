// import Input from 'components/input/input'
// import { Avatar } from 'components/avatar/avatar'
// import discountImg from 'assets/png/discount.png'
// import { FiSearch } from 'react-icons/fi'
// import { Avatar } from 'components/avatar/avatar'
import { ReactComponent as AccounteerLogo } from 'assets/svg/logo-white.svg'
import AccountSwitcher from 'components/account-switcher/account-switcher'
// import Button from 'components/button/button'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import MenuBar from 'components/menu-bar/menu-bar'
// import QuickFind from 'components/quick-find/quick-find'
// import Tag from 'components/tag/tag'
// import { Text } from 'components/text/text'
import { motion } from 'framer-motion'
import {
  HiCash,
  HiChartPie,
  HiClipboardCheck,
  // HiGift,
  HiHome,
  HiOutlineCash,
  HiOutlineChartPie,
  HiOutlineClipboardCheck,
  // HiOutlineGift,
  HiOutlineHome,
  // HiOutlinePresentationChartLine,
  HiOutlineReceiptRefund,
  HiOutlineUsers,
  // HiPresentationChartLine,
  HiReceiptRefund,
  HiUsers,
} from 'react-icons/hi'
// import { MdAccountTree, MdOutlineAccountTree } from 'react-icons/md'
import { BiPurchaseTag, BiSolidPurchaseTag } from 'react-icons/bi'
// import { useNavigate } from 'react-router-dom'
import { DashboardLayoutProps } from './layout.types'
import { StyledCol, StyledRow } from './layouts.styles'
// import QuickFind from 'components/quick-find/quick-find'

const defaultLayoutItems = [
  {
    key: 'dashboard',
    icon: <HiOutlineHome />,
    iconActive: <HiHome />,
    label: 'Dashboard',
    path: '/dashboard',
  },

  {
    key: 'bills',
    icon: <HiOutlineCash />,
    iconActive: <HiCash />,
    label: 'Bills',
    path: '/dashboard/purchases/expenses',
  },
  {
    key: 'purchases',
    icon: <BiPurchaseTag />,
    iconActive: <BiSolidPurchaseTag />,
    label: 'Purchase Order',
    path: '/dashboard/purchases/orders',
  },
  // {
  //   key: 'invoices',
  //   icon: <HiOutlineChartPie />,
  //   iconActive: <HiChartPie />,
  //   label: 'Invoices',
  //   path: '/dashboard/sales/invoices',
  // },
  {
    key: 'Suppliers',
    icon: <HiOutlineUsers />,
    iconActive: <HiUsers />,
    label: 'Suppliers',
    path: '/dashboard/contacts',
  },

  {
    key: 'inventory',
    icon: <HiOutlineClipboardCheck />,
    iconActive: <HiClipboardCheck />,
    label: 'Inventory',
    path: '/dashboard/inventory/items',
    // subMenu: [
    //   {
    //     key: '1',
    //     label: 'Items',
    //     path: '/dashboard/inventory/items',
    //   },
    //   {
    //     key: '2',
    //     label: 'Item Categories',
    //     path: '/dashboard/inventory/item-categories',
    //   },
    // ],
  },

  {
    key: 'taxes',
    icon: <HiOutlineReceiptRefund />,
    iconActive: <HiReceiptRefund />,
    label: 'Taxes',
    path: '/dashboard/taxes/tax-rates',
    // subMenu: [
    //   {
    //     key: '1',
    //     label: 'Tax rates',
    //     path: '/dashboard/taxes/tax-rates',
    //   },
    // ],
  },
  // {
  //   key: 'financial',
  //   icon: <HiOutlineCash />,
  //   iconActive: <HiCash />,
  //   label: 'Financial',
  //   path: '/dashboard/financial/accounts',
  //   subMenu: [
  //     {
  //       key: '1',
  //       label: 'Accounts',
  //       path: '/dashboard/financial/accounts',
  //     },
  //     {
  //       key: '2',
  //       label: 'Bank Rules',
  //       path: '/dashboard/financial/bank-rules',
  //     },
  //   ],
  // },
  // {
  //   key: 'accounting',
  //   icon: <MdOutlineAccountTree />,
  //   iconActive: <MdAccountTree />,
  //   label: 'Accounting',
  //   path: '/dashboard/accounting/charts-of-accounts',
  //   subMenu: [
  //     {
  //       key: '1',
  //       label: 'Charts of accounts',
  //       path: '/dashboard/accounting/charts-of-accounts',
  //     },
  //     {
  //       key: '2',
  //       label: 'Journals',
  //       path: '/dashboard/accounting/journals',
  //     },
  //   ],
  // },
  // {
  //   key: 'reports',
  //   icon: <HiOutlinePresentationChartLine />,
  //   iconActive: <HiPresentationChartLine />,
  //   label: 'Reports',
  //   path: '/dashboard/reports/overview',
  //   subMenu: [
  //     // {
  //     //   key: '1',
  //     //   label: 'Overview',
  //     //   path: '/dashboard/reports/overview',
  //     // },
  //     {
  //       key: '2',
  //       label: 'Balance Sheet',
  //       path: '/dashboard/reports/balance-sheet',
  //     },
  //     {
  //       key: '3',
  //       label: 'Profit & Loss',
  //       path: '/dashboard/reports/profit-loss',
  //     },
  //     // {
  //     //   key: '4',
  //     //   label: 'Custom Reports',
  //     //   path: '/dashboard/reports/custom-reports',
  //     // },
  //   ],
  // },
]

const sidebarWidth = 270

const DefaultLayout: React.FC<DashboardLayoutProps> = (props) => {
  const { children } = props
  // const navigate = useNavigate()

  return (
    <motion.div
      initial={{ opacity: 0, scale: 1.2 }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut',
        },
      }}
      exit={{ opacity: 0, scale: 1.2 }}
    >
      <StyledRow>
        <StyledCol css={{ width: sidebarWidth, background: '#343434' }}>
          <Flex
            direction="column"
            justify="start"
            gutter="5"
            css={{ p: '$3', height: '100%' }}
          >
            <AccounteerLogo
              style={{
                width: '8rem',
              }}
            />
            <Box
              css={{
                position: 'absolute',
                top: '6rem',
                left: '$3',
                width: sidebarWidth - 32,
                backgroundColor: '#555555',
                p: '$3',
                borderRadius: '1rem',
              }}
            >
              <AccountSwitcher />
            </Box>
            <MenuBar data={defaultLayoutItems} />
          </Flex>
        </StyledCol>
        <StyledCol
          css={{
            width: `calc(100vw - ${sidebarWidth}px)`,
            position: 'relative',
            padding: '2rem 20rem',
          }}
        >
          {children}
        </StyledCol>
      </StyledRow>
    </motion.div>
  )
}

export default DefaultLayout
