import { ReactComponent as AccounteerLogo } from 'assets/svg/logo.svg'
import { ReactComponent as LinkSentBg } from 'assets/svg/link-sent.svg'
import Button from 'components/button/button'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { useForgotPasswordMutation } from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import React from 'react'
import { HiChevronLeft } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { extractGraphqlErrors } from 'utils/helpers'
import {
  StyledCol,
  StyledContent,
  StyledLink,
  StyledLogoContainer,
  StyledRow,
} from '../auth.styles'
import { Footer } from '../components/footer/footer'

interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const navigate = useNavigate()
  const [resetLinkSent, setResetLinkSent] = React.useState(false)
  const notify = useToast()
  const { register, values, errors, hasErrors, formIsComplete } = useForm({
    fields: {
      email: '',
    },
  })

  const [{ fetching }, forgotPassword] = useForgotPasswordMutation()

  async function handleForgotPassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      const response = await forgotPassword({
        input: values,
      })
      const error = extractGraphqlErrors(response, 'forgotPassword')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }
      setResetLinkSent(true)
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  return (
    <StyledRow gutter={1}>
      <StyledCol span={4}>
        <StyledContent>
          <StyledLogoContainer align="center" justify="between">
            <AccounteerLogo style={{ width: '7rem' }} />
            <StyledLink to="/">
              <HiChevronLeft color="#ABB3B9" size="1.5rem" />{' '}
              <Text size="xxs">Return to Login</Text>
            </StyledLink>
          </StyledLogoContainer>
          <div>
            {!resetLinkSent && (
              <>
                <Flex gutterY={2} direction={'column'}>
                  <Text size="lg">Reset your password</Text>
                  <Text variant="body2">
                    Forgot your password? We will send you a link to reset your
                    password.
                  </Text>
                </Flex>
                <form onSubmit={handleForgotPassword}>
                  <Flex gutterY={4} direction={'column'}>
                    <Input
                      label="Email Address"
                      placeholder="Enter your email address"
                      required
                      {...register('email')}
                      error={errors.email}
                    />
                    <Button
                      isLoading={fetching}
                      disabled={fetching || hasErrors || !formIsComplete}
                    >
                      Send reset link
                    </Button>
                  </Flex>
                </form>
              </>
            )}
            {resetLinkSent && (
              <>
                <Flex gutterY={6} direction={'column'}>
                  <LinkSentBg />
                  <Flex gutterY={2} direction={'column'}>
                    <Text size="lg">Reset link has been sent!</Text>
                    <Text variant="body2">
                      A reset link was sent to your email. If you don’t see it
                      in a couple of minutes, check your spam folder.
                    </Text>
                  </Flex>

                  <Button onClick={() => navigate('/')}>Back to Login</Button>
                </Flex>
              </>
            )}

            <Footer
              justify="center"
              align="center"
              direction="column"
              gutterY={1}
            />
          </div>
        </StyledContent>
      </StyledCol>
      {/* <AuthBackground /> */}
    </StyledRow>
  )
}

export default ForgotPassword
