import { ReactComponent as EmptyIcon } from 'assets/svg/empty-contact.svg'
import Button from 'components/button/button'
import Flex from 'components/layout/flex'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import { Text } from 'components/text/text'
// import { useToast } from 'components/toast'
import {
  Product,
  // useProductExportMutation,
  useProductsQuery,
} from 'generated/__generated_graphql'
import { useAppProvider } from 'providers/app-provider'
import React, { useState } from 'react'
import {
  // HiArrowCircleDown,
  // HiArrowCircleUp,
  HiDotsHorizontal,
  HiOutlinePlus,
} from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import { formatMoney } from 'utils/helpers'
import { AddNewProductDrawer } from './add-new-product-drawer'
import { ImportCsvDrawer } from './item-import-drawer'
import { ProductOverviewDrawer } from './product-overview-drawer'

export const ItemsTable = () => {
  // const notify = useToast()
  const { organisation } = useAppProvider()
  const shouldOpenDrawer = useLocation().search.includes('openDrawer=true')

  const [showProductDrawer, setShowProductDrawer] =
    React.useState<boolean>(shouldOpenDrawer)
  const [overviewDrawer, setOverviewDrawer] = React.useState<boolean>(false)
  const [showImportProduct, setShowImportProductDrawer] =
    React.useState<boolean>(false)
  const [currentProduct, setCurrentProduct] = useState<Product>()

  const [{ data, fetching }, refetchProducts] = useProductsQuery({
    requestPolicy: 'network-only',
  })

  // const [{ fetching: exporting }, exportProductsMutation] =
  //   useProductExportMutation()

  function onAction(product?: Product) {
    setCurrentProduct(product)
    setOverviewDrawer(true)
  }

  function onEditOverviewDrawer() {
    setOverviewDrawer(false)
    setShowProductDrawer(true)
  }

  function onProductDrawerDismiss() {
    setCurrentProduct(undefined)
    setShowProductDrawer(false)
  }

  function onProductOverviewDismiss() {
    setCurrentProduct(undefined)
    setOverviewDrawer(false)
  }

  // async function exportProducts() {
  //   try {
  //     const response = await exportProductsMutation({
  //       input: { export: true },
  //     })
  //     const error = extractGraphqlErrors(response, 'productExport')

  //     if (error) {
  //       notify({ content: error, status: 'error' })
  //       return
  //     }
  //     const url = response?.data?.productExport?.url
  //     const a = document.createElement('a')
  //     a.href = url as string
  //     a.click()
  //     URL.revokeObjectURL(a.href)

  //     notify({ content: 'Products exported successfully', status: 'success' })
  //   } catch {
  //     notify({ content: 'Something went wrong', status: 'error' })
  //   }
  // }

  const currency = organisation?.currency.symbol

  const columns: ITableColumn<Product>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      render: (name) => (
        <Text size="xs" color="$primary">
          {name}
        </Text>
      ),
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      render: (description) => (
        <Text size="xs" color="$primary">
          {description}
        </Text>
      ),
    },
    {
      key: 'purchasePrice',
      title: 'Purchase Price',
      dataIndex: 'purchasePrice',
      render: (purchasePrice) => (
        <Text size="xs" color="$primary">
          {formatMoney(purchasePrice, currency)}
        </Text>
      ),
    },
    {
      key: 'salePrice',
      title: 'Sale Price',
      dataIndex: 'salePrice',
      render: (salePrice) => (
        <Text size="xs" color="$primary">
          {formatMoney(salePrice, currency)}
        </Text>
      ),
    },
    {
      key: 'quantity',
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (quantity) => (
        <Text size="xs" color="$primary">
          {quantity ?? 0}
        </Text>
      ),
    },
    {
      key: 'action',
      title: '',
      dataIndex: 'id',
      render: (_, product) => (
        <Flex stretchx justify="end">
          <Button onClick={() => onAction(product)} appearance="ghost">
            <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
          </Button>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Table
        showSearch
        loading={fetching}
        columns={columns}
        dataSource={data?.products ?? []}
        actions={
          <Flex gutterX="2">
            {/* <Button
              size="xl"
              appearance="secondary"
              append={<HiArrowCircleDown size="1.3rem" color="#ABB3B9" />}
              onClick={exportProducts}
              disabled={exporting}
            >
              Export
            </Button>
            <Button
              size="xl"
              appearance="secondary"
              append={<HiArrowCircleUp size="1.3rem" color="#ABB3B9" />}
              onClick={() => setShowImportProductDrawer(true)}
            >
              Import CSV
            </Button> */}
            <Button
              size="xl"
              prepend={<HiOutlinePlus color="#fff" />}
              onClick={() => setShowProductDrawer(true)}
            >
              Add Product
            </Button>
          </Flex>
        }
        emptyProps={{
          title: 'Add or Import your products',
          icon: <EmptyIcon />,
          subtitle: `To use Centrum efficiently, add lists of your items or products.`,
          action: (
            <Flex align="center" gutter="4">
              {/* <Button
                appearance="secondary"
                append={<HiArrowCircleUp size="1.3rem" color="#ABB3B9" />}
                size="xl"
                onClick={() => setShowImportProductDrawer(true)}
              >
                Import CSV
              </Button> */}
              <Button
                onClick={() => setShowProductDrawer(true)}
                prepend={<HiOutlinePlus color="#fff" />}
                size="xl"
              >
                Add Product
              </Button>
            </Flex>
          ),
        }}
        pagination={{
          totalCount: data?.products?.length ?? 0,
          currentPage: 1,
          perPage: 10,
          onPageChange: () => null,
          onPaginationClick(type) {
            console.log(type)
          },
        }}
      />

      <AddNewProductDrawer
        product={currentProduct}
        visible={showProductDrawer}
        onClose={onProductDrawerDismiss}
        onSuccess={() => refetchProducts({ requestPolicy: 'network-only' })}
      />
      <ImportCsvDrawer
        visible={showImportProduct}
        onClose={() => {
          setShowImportProductDrawer(false)
        }}
        refetchProducts={() =>
          refetchProducts({ requestPolicy: 'network-only' })
        }
      />
      {currentProduct && (
        <ProductOverviewDrawer
          visible={overviewDrawer}
          onClose={onProductOverviewDismiss}
          onEdit={onEditOverviewDrawer}
          product={currentProduct}
        />
      )}
    </>
  )
}
