import { ReactComponent as AccounteerLogo } from 'assets/svg/logo.svg'
import Button from 'components/button/button'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import React from 'react'

import PhoneNumberInput from 'components/phone-number-input'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { useRegisterMutation } from 'generated/__generated_graphql'
import { HiChevronLeft } from 'react-icons/hi'
import { countries } from 'utils/countries'
import { extractGraphqlErrors } from 'utils/helpers'

import { zodResolver } from '@hookform/resolvers/zod'
import Separator from 'components/separator/separator'
import { motion } from 'framer-motion'
import { GoogleAuthenticationButton } from 'hooks/useGoogleAuthentication'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { email, firstName, lastName, password, phone } from 'utils/schemas'
import { z } from 'zod'
import {
  StyledCol,
  StyledContent,
  StyledLink,
  StyledLogoContainer,
  StyledRow,
} from '../auth.styles'
import { Footer } from '../components/footer/footer'
import RegisterSuccessful from './register-successful'

const formSchema = z.object({
  firstName,
  lastName,
  email,
  password,
  phone,
})

type FormFields = z.infer<typeof formSchema>

interface RegisterProps {}

const Register: React.FC<RegisterProps> = () => {
  const location = useLocation()
  const [successfullyRegistered, setSuccessfullyRegistered] =
    React.useState(false)
  const toast = useToast()
  const {
    control,
    register,
    formState: { errors, isSubmitting, isValid },
    getValues,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  const [, registerAccount] = useRegisterMutation()

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const plan = searchParams.get('plan')

    if (plan) {
      localStorage.setItem('plan', plan)
    }
  }, [location.search])

  function notifyError(error?: string) {
    toast({
      content: error ?? 'something went wrong',
      status: 'error',
    })
  }

  async function onSubmit() {
    try {
      const response = await registerAccount({ input: getValues() })
      const error = extractGraphqlErrors(response, 'register')

      if (error) {
        notifyError(error)
        return
      }
      setSuccessfullyRegistered(true)
      localStorage.setItem('email', getValues().email)
    } catch (error) {
      notifyError(String(error))
    }
  }

  if (successfullyRegistered) return <RegisterSuccessful />

  return (
    <StyledRow gutter={1}>
      <StyledCol span={4}>
        <motion.div
          style={{
            minHeight: '100%',
          }}
          initial={{ opacity: 0, x: -300 }}
          animate={{ opacity: 1, x: 0, transition: { duration: 0.25 } }}
          exit={{ opacity: 0, x: -300 }}
        >
          <StyledContent>
            <StyledLogoContainer align="center" justify="between">
              <AccounteerLogo style={{ width: '7rem' }} />
              <Flex align="center" gutter={1}>
                <HiChevronLeft color="#ABB3B9" size="1.5rem" />
                <Text size="xxs">Already have an account?</Text>
                <StyledLink to="/"> Login</StyledLink>
              </Flex>
            </StyledLogoContainer>
            <>
              <Flex className="flex" gutterY={2} direction={'column'}>
                <Text size="lg">Join Centrum</Text>
                <Text color="$secondary" size="xs" css={{ mb: '5rem' }}>
                  Tell us a bit about yourself to get started ...
                </Text>

                <GoogleAuthenticationButton
                  setSuccessfullyRegistered={setSuccessfullyRegistered}
                  mode="sign-up"
                  disabled={isSubmitting}
                />

                <Flex align={'center'}>
                  <Separator color="$disabled" />
                  <Text
                    size="xxs"
                    weight="bold"
                    color="gray"
                    css={{
                      whiteSpace: 'nowrap',
                      mx: '1.5rem',
                    }}
                  >
                    or
                  </Text>
                  <Separator color="$disabled" />
                </Flex>
              </Flex>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex gutterY={4} direction={'column'}>
                  <Flex align="start" justify="between" gutterX={2}>
                    <Input
                      {...(register('firstName') as any)}
                      label="First name"
                      placeholder="Enter your first name"
                      required
                      error={errors.firstName?.message}
                    />
                    <Input
                      {...(register('lastName') as any)}
                      label="Last name"
                      placeholder="Enter your last name"
                      required
                      error={errors.lastName?.message}
                    />
                  </Flex>
                  {/* <Input
                  {...(register('firstName') as any)}
                  label="First name"
                  placeholder="Enter your first name"
                  required
                  error={errors.firstName?.message}
                />
                <Input
                  {...(register('lastName') as any)}
                  label="Last name"
                  placeholder="Enter your last name"
                  required
                  error={errors.lastName?.message}
                /> */}
                  <Input
                    label="Email Address"
                    placeholder="example@centruminc.com"
                    required
                    {...(register('email', {
                      setValueAs: (value) => (value as string).toLowerCase(),
                    }) as any)}
                    error={errors.email?.message}
                  />
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { onChange } }) => {
                      return (
                        <PhoneNumberInput
                          label="Phone Number"
                          required
                          onChange={(phoneNumberData) => {
                            onChange(
                              `${phoneNumberData.dialingCode} ${phoneNumberData.phoneNumber}`
                            )
                          }}
                          error={errors.phone?.message}
                          countries={countries}
                        />
                      )
                    }}
                  />
                  <Input
                    {...(register('password') as any)}
                    label="Password"
                    placeholder="Password"
                    type="password"
                    required
                    error={errors.password?.message}
                  />

                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting || !isValid}
                  >
                    Create account
                  </Button>
                </Flex>
              </form>
              <Footer
                justify="center"
                align="center"
                direction="column"
                gutterY={1}
              />
            </>
          </StyledContent>
        </motion.div>
      </StyledCol>
      {/* <AuthBackground /> */}
    </StyledRow>
  )
}

export default Register
