import Box from 'components/layout/box'
import Col from 'components/layout/col'
import Flex from 'components/layout/flex'
import Row from 'components/layout/row'
import { Text } from 'components/text/text'
import { Link } from 'react-router-dom'
import { styled } from 'stitches/stitches.config'

export const StyledRow = styled(Row, {
  background: '#F7F7F7',
  width: '100%',
  height: '100vh',

  // placeItems: 'center',

  '.pattern': {
    position: 'absolute',
    top: '0',
    right: '0',
    width: '100%',
    // display: 'none',
  },
})

export const StyledCol = styled(Col, {
  overflow: 'auto',
  padding: '$3',
  maxWidth: 490,
  width: 'fit-content',
  zIndex: 1,
})

export const StyledLogoContainer = styled(Flex, {
  mb: '$9',
  '.icon': {
    // width: '13rem',
  },
})

export const StyledContent = styled(Box, {
  position: 'relative',
  background: '$white',
  borderRadius: '$3',
  boxShadow: '$tiny',
  px: '$6',
  py: '$5',
  width: '100%',
  minHeight: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '&>.flex': {
    mt: 'auto',
    width: '100%',
  },

  a: {
    fontSize: '$2',
    color: '$primary',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    transition: 'all 0.3s ease',

    '&:hover': {
      color: '$blue',
    },
  },

  '.get-started': {
    color: '$blue',
  },

  form: {
    mt: '2rem',
  },
})

export const StyledFooter = styled(Flex, {
  mt: '4rem',
  mb: '1.5rem',
  width: '100%',

  variants: {
    extraMargin: {
      true: {
        mt: '10.75rem',
      },
    },
  },

  // p: {
  //   fontSize: '1.2rem',
  //   color: '$secondary',
  // },

  a: {
    // fontSize: '1.2rem',
    color: '$secondary',
  },
})

export const StyledBackground = styled(Col, {
  position: 'relative',
  overflow: 'hidden',
  marginLeft: 'auto',

  '.bg': {
    width: '65%',
    marginLeft: 'auto',
    marginTop: '10rem',

    img: {
      width: '65%',
      position: 'absolute',
      bottom: '0',
      right: '0',
    },
  },
})

export const StyledLink = styled(Link, {
  fontSize: '$1 !important',
  display: 'flex',
  alignItems: 'center',
  color: '$blue !important',
})

export const StyledSmall = styled(Text, {
  mb: '1.2rem',
})
